<template>
  <div>
    <div class="sneaker-detail-content">
      <div class="image">
        <v-spinner
          :loading="true"
          v-if="imageLoading"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
        <img
          v-on:click="clickImage"
          v-show="!sneaker.image && !imageLoading"
          src="../../../public/no_image.png"
          width="688"
          height="387"
        />
        <img
          v-on:click="clickImage"
          v-show="sneaker.image && !imageLoading"
          v-bind:src="sneaker.image"
          width="688"
          height="387"
        />
        <input
          ref="image"
          style="display: none"
          type="file"
          v-on:change="setImage"
          accept="image/*"
        />
      </div>
      <div class="detail-content">
        <v-header
          :to="{ name: 'sneakers' }"
          :text="$t('_sneakers_create.add_sneaker')"
        />
        <form class="form" @submit.prevent="createSneaker" novalidate>
          <div class="attributes">
            <div class="row">
              <h3>
                {{ $t("_sneakers_create.name") }}
              </h3>
              <v-input
                type="text"
                v-model="sneaker.name"
                :error="$v.sneaker.name"
              />
              <h3>
                {{ $t("_sneakers_create.date_bought") }}
              </h3>
              <v-input
                type="date"
                v-model="sneaker.date_bought"
                :error="$v.sneaker.date_bought"
              />
              <h3>
                {{ $t("_sneakers_create.size") }}
              </h3>
              <v-input
                type="number"
                min="36"
                max="50"
                step="0.5"
                v-model="sneaker.size"
                :error="$v.sneaker.size"
              />
              <h3>
                {{ $t("_sneakers_create.money_payed") }}
              </h3>
              <v-input
                type="number"
                min="0"
                step="0.01"
                v-model="sneaker.money_payed"
                :error="$v.sneaker.money_payed"
              />
            </div>
            <div class="row">
              <v-select
                :options="type_options"
                :values="type_options"
                :label="$t('_sneakers_create.type_options')"
                :placeholder="$t('_sneakers_create.type_options_placeholder')"
                v-on:setValue="setSneakerType"
                :error="$v.sneaker.type"
              />
              <h3>
                {{ $t("_sneakers_create.aim_price") }}
              </h3>
              <v-input
                type="number"
                min="0"
                step="0.01"
                v-model="sneaker.aim_price"
                :error="$v.sneaker.aim_price"
              />
              <v-select
                :options="size_types"
                :values="size_types"
                :label="$t('_sneakers_create.size_type')"
                :placeholder="$t('_sneakers_create.size_type_placeholder')"
                v-on:setValue="setSizeType"
                :error="$v.sneaker.size_type"
              />
              <v-select
                :options="status_options"
                :values="status_options"
                :label="$t('_sneakers_create.status')"
                :placeholder="$t('_sneakers_create.status_placeholder')"
                v-on:setValue="setSneakerStatus"
                :error="$v.sneaker.status"
              />
            </div>
            <div class="last_row">
              <h3 v-show="sneaker.status == 'Verkocht'">
                {{ $t("_sneakers_create.sold_for") }}
              </h3>
              <v-input
                ref="sneaker_sold_for"
                v-show="sneaker.status == 'Verkocht'"
                v-on:changeValue="changeSoldFor"
                type="number"
                min="0"
                step="0.01"
                v-model="sneaker.sold_for"
                :error="$v.sneaker.sold_for"
              />
              <h3 v-show="sneaker.status == 'Verkocht'">
                {{ $t("_sneakers_create.date_sold") }}
              </h3>
              <v-input
                ref="sneaker_date_sold"
                v-show="sneaker.status == 'Verkocht'"
                v-on:changeValue="changeDateSold"
                type="date"
                v-model="sneaker.date_sold"
                :error="$v.sneaker.date_sold"
              />
            </div>
          </div>
          <v-button :label="$t('_buttons.add')" position="mini" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "sneakerscreate",
  data() {
    return {
      status_options: [
        this.$t("_sneakers_create._status.available"),
        this.$t("_sneakers_create._status.sold"),
        this.$t("_sneakers_create._status.in_transit"),
        this.$t("_sneakers_create._status.reserved"),
        this.$t("_sneakers_create._status.not_payed"),
        this.$t("_sneakers_create._status.in_treatment"),
      ],
      size_types: [
        this.$t("_sneakers_create._size_type.men"),
        this.$t("_sneakers_create._size_type.woman"),
        this.$t("_sneakers_create._size_type.older_kids"),
      ],
      type_options: [
        this.$t("_sneakers_create._type.air_jordan_1_high_og"),
        this.$t("_sneakers_create._type.air_jordan_1_retro_high"),
        this.$t("_sneakers_create._type.air_jordan_1_mid"),
        this.$t("_sneakers_create._type.air_jordan_1_low"),
        this.$t("_sneakers_create._type.air_jordan_4"),
        this.$t("_sneakers_create._type.air_jordan_5"),
        this.$t("_sneakers_create._type.air_jordan_6"),
        this.$t("_sneakers_create._type.air_force_1"),
        this.$t("_sneakers_create._type.air_max_1"),
        this.$t("_sneakers_create._type.air_max_90"),
        this.$t("_sneakers_create._type.air_max_95"),
        this.$t("_sneakers_create._type.air_max_97"),
        this.$t("_sneakers_create._type.dunk_low"),
        this.$t("_sneakers_create._type.dunk_high"),
        this.$t("_sneakers_create._type.sb_dunk_low_pro"),
        this.$t("_sneakers_create._type.sb_dunk_high_pro"),
        this.$t("_sneakers_create._type.yeezy"),
        this.$t("_sneakers_create._type.other"),
      ],
      sneaker: {
        name: "",
        date_bought: undefined,
        size: undefined,
        money_payed: undefined,
        type: undefined,
        status: "",
        aim_price: undefined,
        sold_for: undefined,
        image: undefined,
        size_type: undefined,
        date_sold: undefined,
      },
      imageLoading: false,
      balance: {},
    };
  },
  validations: {
    sneaker: {
      name: {
        required,
      },
      size: {
        required,
        minValue: minValue(36),
        maxValue: maxValue(50),
      },
      date_bought: {
        required,
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
      },
      money_payed: {
        required,
        minValue: minValue(0.01),
      },
      type: {
        required,
      },
      status: {
        required,
      },
      size_type: {
        required,
      },
      aim_price: {
        required,
        minValue: minValue(0.01),
      },
      sold_for: {
        minValue: minValue(0.01),
        required: requiredIf(function() {
          return this.sneaker.status == "Verkocht";
        }),
      },
      date_sold: {
        required: requiredIf(function() {
          return this.sneaker.status == "Verkocht";
        }),
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
        checkDateSold: function(value) {
          if (value) {
            return value >= this.sneaker.date_bought;
          }
          return true;
        },
      },
    },
  },
  async created() {},
  methods: {
    setSneakerStatus(status) {
      this.$refs["sneaker_sold_for"].reset();
      this.$refs["sneaker_date_sold"].reset();
      this.sneaker.status = status;
    },
    setSneakerType(type) {
      this.sneaker.type = type;
    },
    setSizeType(type) {
      this.sneaker.size_type = type;
    },
    createSneaker() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store
        .dispatch("sneakers/create", this.sneaker)
        .then(() => {
          if (this.sneaker.status != "Verkocht") {
            this.balance = {
              new_expense: parseFloat(this.sneaker.money_payed),
              new_revenue: 0,
              date_bought: this.sneaker.date_bought,
            };
          }
          if (this.sneaker.status == "Verkocht") {
            this.balance = {
              new_expense: parseFloat(this.sneaker.money_payed),
              new_revenue: parseFloat(this.sneaker.sold_for),
              date_bought: this.sneaker.date_bought,
            };
          }
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.sneaker.date_bought,
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        })
        .finally(() => {
          this.$router.push({ name: "sneakers" });
          this.$parent.showCheckMessage(
            this.$t("_sneakers_create.created_success")
          );
          this.$v.$reset();
        });
    },
    changeSoldFor(value) {
      this.sneaker.sold_for = value;
    },
    changeDateSold(value) {
      this.sneaker.date_sold = value;
    },
    setImage(event) {
      this.imageLoading = true;
      if (event) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          this.sneaker.image = event.target.result;
          this.imageLoading = false;
        };
        reader.onerror = function(error) {
          alert(error);
          this.imageLoading = false;
        };
      }
    },
    clickImage() {
      this.$refs["image"].click();
    },
  },
  metaInfo() {
    return { title: this.$t("_sneakers_create.page_title") };
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-height: 800px) {
  .image img {
    width: 688px;
    height: 387px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .image img {
    width: 450px;
    height: 253px;
  }

  .attributes {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 612px;
    height: calc(100vh - 235px);
  }
}

.sneaker-detail-content {
  display: flex;
  margin-bottom: 40px;

  .image {
    margin: 0px 60px 0px 0px;
    cursor: pointer;
  }

  .detail-content {
    .form {
      margin-bottom: 40px;

      h3 {
        margin: 0px 0px 5px 21px;
      }

      v-input {
        max-height: 12px;
      }

      .input-holder {
        margin-bottom: 30px;
      }

      .select-content,
      .input-holder {
        max-width: 256px;
      }
    }
  }

  .attributes {
    display: flex;

    .row {
      min-width: 256px;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
    }

    .last_row {
      min-width: 256px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
