import AppAPI from "../../api/app";
import store from "../../store";
require("dotenv").config();

/* eslint-disable no-empty-pattern*/

export const state = {
  list: [],
};

export const getters = {
  list: (state) => state.list,
  single: (state) => (id) => {
    return store.findItem(state.list, id);
  },
};

export const actions = {
  async fetchSneakers({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers")
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchAvailableSneakers({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?status=Beschikbaar")
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchUniqueYears() {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?distinctYears=true")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakerTypes() {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?sneakertypes=true")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakerTypesByMonth({}, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("sneakers/" + payload.year + "/types", payload)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchAllowance({}, brand) {
    return new Promise((resolve, reject) => {
      AppAPI.get("allowance?car=" + brand)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakersByType({}, type) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?type=" + type)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchUniqueMonths({}, year) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers/distinctmonths?year=" + year)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchListByFilter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("sneakers/filter", payload)
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakerTypesByFilter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("sneakers/filter?sneakertypes=true", payload)
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSingle({}, id) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers/" + id)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("sneakers", payload)
        .then(function(response) {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.put("sneakers/" + payload.id, payload)
        .then(function(response) {
          commit("UPDATE", response.data.id);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.delete("sneakers/" + payload.id)
        .then(function(response) {
          commit("DELETE", payload);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export const mutations = {
  SET(state, list) {
    state.list = list;
  },
  UPDATE(state, single) {
    this.updateItem(state.list, single);
  },
  DELETE(state, single) {
    this.deleteItem(state.list, single);
  },
};
