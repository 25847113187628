<template>
  <section class="authenticate-container">
    <alert ref="alert" />
    <main class="authenticate-content">
      <router-view />
    </main>
  </section>
</template>

<script>
export default {
  name: "Authenticate",
  methods: {
    showErrorMessage(message) {
      this.$refs.alert.showErrorMessage(message);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.authenticate-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  overflow: scroll;

  .authenticate-content {
    margin: 20px;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    max-width: 500px;
    padding: 40px 50px;
    border-radius: 5px;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);
  }
}
</style>
