<template>
  <main>
    <header class="main-navigation">
      <router-link class="page-title" tag="a" :to="{ name: 'dashboard' }">
        <img class="logo" src="../../assets/logo.png" />
        <h3>{{ $t("_navigation.title") }}</h3>
      </router-link>
      <div class="user" v-on:click="open()">
        <h4>{{ firstname }}</h4>
        <MenuDown v-if="!isOpened" title="" fillColor="var(--icon-color)" />
        <MenuUp v-if="isOpened" title="" fillColor="var(--icon-color)" />
      </div>
    </header>
    <logout :open="isOpened" :user="firstname + ' ' + lastname" />
  </main>
</template>

<script>
import MenuDown from "vue-material-design-icons/MenuDown.vue";
import MenuUp from "vue-material-design-icons/MenuUp.vue";
import Logout from "../ux/Logout";

export default {
  name: "Navbar",
  components: { MenuDown, MenuUp, Logout },
  data() {
    return {
      firstname: "",
      lastname: "",
      isOpened: false,
    };
  },
  methods: {
    open() {
      this.isOpened = !this.isOpened;
    },
  },
  async created() {
    this.$store
      .dispatch("authenticate/fetchUser")
      .then((res) => {
        this.firstname = res.data.firstname;
        this.lastname = res.data.lastname;
      })
      .catch(() => {});
  },
};
</script>

<style scoped lang="scss">
.main-navigation {
  box-sizing: border-box;
  height: 90px;
  width: 100%;
  background-color: #fff;
  border-bottom: var(--sidebar-border-width-color) solid
    var(--sidebar-border-color);
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user {
    display: flex;
    line-height: 24px;
    color: var(--primary-color);
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .page-title {
    display: flex;
    align-items: center;
    cursor: pointer;

    color: inherit;

    .logo {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    h3 {
      margin-left: 20px;
    }
  }
}
</style>
