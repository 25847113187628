var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"horizontal"},[_c('div',{staticClass:"money"},[_c('div',{staticClass:"balance"},[(_vm.months.length > 0)?_c('Select',{attrs:{"text":_vm.$t('_dashboard.balance'),"months":_vm.months},on:{"setValue":_vm.setMonthBalance}}):_vm._e(),(_vm.isLoadingBalance)?_c('v-spinner',{attrs:{"loading":true,"color":'#3e3e3e',"size":'30px'}}):_vm._e(),(!_vm.isLoadingBalance && _vm.balance)?_c('Balance',{attrs:{"balance":_vm.balance}}):_vm._e()],1),_c('div',{staticClass:"payouts"},[(_vm.months.length > 0)?_c('Select',{attrs:{"text":_vm.$t('_dashboard.payouts'),"months":_vm.months},on:{"setValue":_vm.setMonthPayouts}}):_vm._e(),(_vm.isLoadingPayouts)?_c('v-spinner',{attrs:{"loading":true,"color":'#3e3e3e',"size":'30px'}}):_vm._e(),(!_vm.isLoadingPayouts && _vm.payouts)?_c('Payouts',{attrs:{"payouts":_vm.payouts}}):_vm._e()],1)]),_c('div',{staticClass:"sneakers"},[_c('h1',[_vm._v(_vm._s(_vm.$t("_dashboard.available_sneakers")))]),_c('div',{staticClass:"available_sneakers"},[(_vm.available_sneakers.length == 0 && !_vm.isLoadingSneakers)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("_sneakers.no_sneakers"))+" ")]):_vm._e(),_vm._l((_vm.available_sneakers),function(sneaker){return _c('Sneaker',{key:sneaker.id,attrs:{"to":{
            name: 'sneakersdetail',
            params: { id: sneaker.id, last: _vm.last },
          },"sneaker":sneaker}})}),(_vm.isLoadingSneakers)?_c('v-spinner',{attrs:{"loading":true,"color":'#3e3e3e',"size":'30px'}}):_vm._e()],2)])]),_c('div',{staticClass:"declarations"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("_dashboard.submitted_declarations")))]),_c('headers',{attrs:{"headers":_vm.headers}}),_c('div',{staticClass:"submitted-declarations"},[(_vm.isLoadingDeclarations)?_c('v-spinner',{attrs:{"loading":true,"color":'#3e3e3e',"size":'30px'}}):_vm._e(),(_vm.submitted_declarations.length == 0 && !_vm.isLoadingDeclarations)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("_declarations.no_declarations"))+" ")]):_vm._e(),_vm._l((_vm.submitted_declarations),function(declaration){return _c('declaration',{key:declaration.id,attrs:{"to":{
          name: 'declarationsdetail',
          params: { id: declaration.id, last: _vm.last },
        },"declaration":[
          declaration.reason,
          declaration.date,
          declaration.type,
          declaration.amount.toFixed(2),
          declaration.status ]}})})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }