<template>
  <select v-model="selected" v-on:change="onChange()">
    <option v-for="month in months" :key="month.month" :value="month"
      >{{ text }} {{ month.month }} {{ month.year }}</option
    >
  </select>
</template>

<script>
export default {
  name: "Select",
  props: {
    months: [],
    text: {},
  },
  methods: {
    setSelected() {
      this.selected = this.months[0];
      this.$emit("setValue", this.months[0]);
    },
    onChange() {
      this.$emit("setValue", this.selected);
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  async mounted() {
    this.setSelected();
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 29px;
  width: 340px;
  border-width: 0px;
  background: none;
  font-size: 15pt;
  font-weight: bold;
  color: #2c3e50;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 4px;
  -webkit-appearance: none;
  margin-bottom: 5px;
}

select:focus {
  outline: none !important;
}
</style>
