<template>
  <router-link :to="to">
    <div class="header">
      <ArrowLeft />
      <h1>{{ text }}</h1>
    </div>
  </router-link>
</template>

<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";

export default {
  name: "VHeader",
  components: { ArrowLeft },
  props: {
    to: {},
    text: {},
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  text-align: center;
  line-height: 24px;
  margin-bottom: 30px;

  h1 {
    margin-left: 25px;
  }
}

a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
</style>
