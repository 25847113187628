<template>
  <header class="main-sidebar">
    <div class="router-links">
      <router-link :to="{ name: 'dashboard' }">
        <Home :size="36" title="Dashboard" />
      </router-link>
      <router-link :to="{ name: 'sneakers' }">
        <ShoeSneaker :size="36" title="Sneakers" />
      </router-link>
      <router-link :to="{ name: 'declarations' }">
        <CreditCardSearch :size="30" title="Declaraties" />
      </router-link>
    </div>
  </header>
</template>

<script>
import Home from "vue-material-design-icons/Home.vue";
import ShoeSneaker from "vue-material-design-icons/ShoeSneaker.vue";
import CreditCardSearch from "vue-material-design-icons/CreditCardSearch.vue";

export default {
  name: "Sidebar",
  components: { Home, ShoeSneaker, CreditCardSearch },
};
</script>

<style scoped lang="scss">
.main-sidebar {
  display: flex;
  box-sizing: border-box;
  height: calc(100vh - 90px);
  min-width: 60px;
  background-color: #fff;
  border-right: var(--sidebar-border-width-color) solid
    var(--sidebar-border-color);
  padding: 30px 5px;
  justify-content: center;
  top: 90px;

  a {
    cursor: pointer;
    color: var(--icon-color);
    margin: 0px auto 30px auto;
  }

  .router-links {
    display: flex;
    flex-direction: column;
  }

  .router-link-exact-active {
    color: var(--icon-color-selected);
  }
}
</style>
