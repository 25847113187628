import Vue from "vue";
import VueI18n from "vue-i18n";

// Translation files
import i18nEn from "./languages/nl.json";

Vue.use(VueI18n);

const messages = {
  nl: i18nEn,
};

export const i18n = new VueI18n({
  locale: "nl",
  messages,
});
