<template>
  <div v-if="open" class="logout-content">
    <p>{{ user }}</p>
    <h4 v-on:click="logout()">{{ $t("_buttons.logout") }}</h4>
  </div>
</template>

<script>
export default {
  name: "Logout",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    user: {},
  },
  methods: {
    logout() {
      this.$store.dispatch("authenticate/logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-content {
  margin-top: -30px;
  box-sizing: border-box;
  width: 200px;
  height: 90px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: absolute;
  right: 30px;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  z-index: 1;
}

p {
  font-size: 14px;
  margin-bottom: 10px;
  cursor: default;
}

h4 {
  cursor: pointer;
}
</style>
