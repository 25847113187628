<template>
  <section class="default-container">
    <alert ref="alert" />
    <navigation />
    <div class="container">
      <sidebar />
      <main class="default-content">
        <router-view />
      </main>
    </div>
  </section>
</template>

<script>
import Navigation from "../components/layout/Navigation.vue";
import Sidebar from "../components/layout/Sidebar.vue";

export default {
  components: { Navigation, Sidebar },
  name: "default",
  methods: {
    showErrorMessage(message) {
      this.$refs.alert.showErrorMessage(message);
    },
    showCheckMessage(message) {
      this.$refs.alert.showCheckMessage(message);
    },
  },
};
</script>

<style lang="scss" scoped>
.default-container {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .default-content {
    width: calc(100vw - 60px);
    height: calc(100vh - 90px);
    min-height: 500px;
    padding: 40px;
  }

  .container {
    display: flex;
    flex-direction: row;
  }
}
</style>
