<template>
  <div class="sign-in">
    <div class="border">
      <img
        class="logo"
        v-on:load="imgLoad"
        src="../../assets/logo.png"
        width="320"
      />
      <v-spinner
        :loading="true"
        v-if="imgLoading"
        :color="'#3e3e3e'"
        :size="'30px'"
      ></v-spinner>
    </div>
    <div class="greeting">
      <h1>{{ $t("_sign-in.title") }}</h1>
      <p>{{ $t("_sign-in.subtitle") }}</p>
    </div>
    <form @submit.prevent="login" novalidate>
      <v-input
        :label="$t('_sign-in.email')"
        :required="true"
        :error="$v.user.email"
        v-model="user.email"
      />
      <v-input
        type="password"
        :label="$t('_sign-in.password')"
        :required="true"
        :error="$v.user.password"
        v-model="user.password"
      />
      <v-button
        :label="$t('_buttons.login')"
        position="medium"
        :isLoading="isLoading"
        :disabled="isLoading"
      />
    </form>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "SignIn",
  data() {
    return {
      isLoading: false,
      imgLoading: true,
      user: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    imgLoad() {
      this.imgLoading = false;
    },
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isLoading = true;
      this.$store
        .dispatch("authenticate/login", this.user)
        .then(() => {
          this.$router.push({
            name: "dashboard",
            params: { justLoggedIn: true },
          });
        })
        .catch(() => {
          this.$parent.showErrorMessage(this.$t("_errors.unknown_user_info"));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  metaInfo() {
    return { title: this.$t("_sign-in.page_title") };
  },
};
</script>

<style lang="scss" scoped>
.sign-in {
  button {
    margin: 30px auto 0px;
    display: block;
  }

  .greeting {
    h1,
    p {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .border {
    min-height: 222px;

    .logo {
      margin: 0px auto 40px;
      display: block;
    }
  }
}
</style>
