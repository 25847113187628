<template>
  <div class="checkbox-content">
    <input type="checkbox" v-model="toggle" v-on:change="onChange()" />
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "VCheckbox",
  props: {
    text: {},
  },
  data() {
    return {
      toggle: "",
    };
  },
  methods: {
    onChange() {
      this.$emit("changeFilter", this.text, this.toggle);
    },
    reset() {
      this.toggle = false;
      this.$emit("changeFilter", this.text, this.toggle);
    },
  },
};
</script>

<style lang="scss">
.checkbox-content {
  display: flex;

  input {
    margin: 10px 10px 10px 0px;
  }

  p {
    text-align: center;
    line-height: 33px;
  }
}
</style>
