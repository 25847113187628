<template>
  <router-link tag="a" :to="to">
    <div class="sneaker-content">
      <div class="sneaker">
        <div class="image">
          <img
            v-show="!sneaker.image"
            src="../../../public/no_image.png"
            width="153"
            height="86"
          />
          <img
            v-show="sneaker.image"
            v-bind:src="sneaker.image"
            width="153"
            height="86"
          />
        </div>
        <div class="sneaker_attributes">
          <h4>{{ sneaker.type }} - {{ sneaker.name }}</h4>
          <h5 class="date">{{ sneaker.date_bought_formatted }}</h5>
          <div class="attribute">
            <p>Maat:</p>
            <p class="bold">{{ sneaker.size }}</p>
          </div>
          <div class="attribute">
            <p>Prijs:</p>
            <p class="bold" v-if="sneaker.status != 'Verkocht'">
              €{{ sneaker.aim_price.toFixed(2) }}
            </p>
            <p class="bold" v-if="sneaker.status == 'Verkocht'">
              €{{ sneaker.sold_for.toFixed(2) }}
            </p>
          </div>
        </div>
      </div>
      <div class="status">
        <p>{{ sneaker.status }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "VSneaker",
  props: {
    sneaker: {},
    to: {},
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sneaker-content {
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .sneaker {
    display: flex;

    .image {
      margin-right: 60px;
    }

    .sneaker_attributes {
      display: flex;
      flex-direction: column;

      .date {
        color: #aeb3bb;
        margin-bottom: 12px;
      }

      .attribute {
        display: flex;
        margin-bottom: 4px;

        p {
          margin-right: 5px;
          font-weight: 300;
          font-size: 11pt;
        }

        .bold {
          font-weight: 500;
        }
      }
    }
  }

  .status {
    line-height: 86px;
    margin-right: 20px;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
</style>
