<template>
  <div class="dashboard-content">
    <div class="horizontal">
      <div class="money">
        <div class="balance">
          <Select
            v-if="months.length > 0"
            :text="$t('_dashboard.balance')"
            :months="months"
            v-on:setValue="setMonthBalance"
          />
          <v-spinner
            :loading="true"
            v-if="isLoadingBalance"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
          <Balance v-if="!isLoadingBalance && balance" :balance="balance" />
        </div>
        <div class="payouts">
          <Select
            v-if="months.length > 0"
            :text="$t('_dashboard.payouts')"
            :months="months"
            v-on:setValue="setMonthPayouts"
          />
          <v-spinner
            :loading="true"
            v-if="isLoadingPayouts"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
          <Payouts v-if="!isLoadingPayouts && payouts" :payouts="payouts" />
        </div>
      </div>
      <div class="sneakers">
        <h1>{{ $t("_dashboard.available_sneakers") }}</h1>
        <div class="available_sneakers">
          <p v-if="available_sneakers.length == 0 && !isLoadingSneakers">
            {{ $t("_sneakers.no_sneakers") }}
          </p>
          <Sneaker
            v-for="sneaker in available_sneakers"
            :key="sneaker.id"
            :to="{
              name: 'sneakersdetail',
              params: { id: sneaker.id, last: last },
            }"
            :sneaker="sneaker"
          />
          <v-spinner
            :loading="true"
            v-if="isLoadingSneakers"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
        </div>
      </div>
    </div>

    <div class="declarations">
      <h1 class="title">{{ $t("_dashboard.submitted_declarations") }}</h1>
      <headers :headers="headers" />
      <div class="submitted-declarations">
        <v-spinner
          :loading="true"
          v-if="isLoadingDeclarations"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
        <p v-if="submitted_declarations.length == 0 && !isLoadingDeclarations">
          {{ $t("_declarations.no_declarations") }}
        </p>
        <declaration
          v-for="declaration in submitted_declarations"
          :key="declaration.id"
          :to="{
            name: 'declarationsdetail',
            params: { id: declaration.id, last: last },
          }"
          :declaration="[
            declaration.reason,
            declaration.date,
            declaration.type,
            declaration.amount.toFixed(2),
            declaration.status,
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Sneaker from "./components/Sneaker";
import Balance from "./components/Balance";
import Select from "./components/Select";
import Payouts from "./components/Payouts";

export default {
  name: "Dashboard",
  components: { Sneaker, Balance, Select, Payouts },
  async created() {
    this.getAvailableSneakers();
    this.getSubmittedDeclarations();
    this.getBalanceMonths();
    if (this.$route.params.justLoggedIn) {
      this.$parent.showCheckMessage(this.$t("_sign-in.login_success"));
    }
  },
  methods: {
    getAvailableSneakers() {
      this.isLoadingSneakers = true;
      this.$store
        .dispatch("sneakers/fetchAvailableSneakers")
        .then((res) => {
          this.available_sneakers = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneakers = false;
        })
        .finally(() => {
          this.isLoadingSneakers = false;
        });
    },
    getSubmittedDeclarations() {
      this.isLoadingDeclarations = true;
      this.$store
        .dispatch("declarations/fetchSubmittedDeclarations")
        .then((res) => {
          this.submitted_declarations = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingDeclarations = false;
        })
        .finally(() => {
          this.isLoadingDeclarations = false;
        });
    },
    getBalanceMonths() {
      this.$store
        .dispatch("balance/fetchBalanceMonths")
        .then((res) => {
          this.months = res.data.months;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        });
    },
    setMonthBalance(month) {
      this.selectedBalanceMonth = month;
      this.isLoadingBalance = true;
      this.$store
        .dispatch("balance/fetchBalance", this.selectedBalanceMonth)
        .then((res) => {
          this.balance = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingBalance = false;
        })
        .finally(() => {
          this.isLoadingBalance = false;
        });
    },
    setMonthPayouts(month) {
      this.selectedPayoutsMonth = month;
      this.isLoadingPayouts = true;
      this.$store
        .dispatch("payouts/fetchPayouts", this.selectedPayoutsMonth)
        .then((res) => {
          this.payouts = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingPayouts = false;
        })
        .finally(() => {
          this.isLoadingPayouts = false;
        });
    },
  },
  data() {
    return {
      isLoadingSneakers: false,
      isLoadingDeclarations: false,
      isLoadingBalance: false,
      isLoadingPayouts: false,
      available_sneakers: {},
      submitted_declarations: {},
      last: { name: "dashboard", id: "" },
      headers: [
        this.$t("_declarations._headers.reason"),
        this.$t("_declarations._headers.date"),
        this.$t("_declarations._headers.type"),
        this.$t("_declarations._headers.amount"),
        this.$t("_declarations._headers.status"),
      ],
      months: [],
      selectedBalanceMonth: {},
      selectedPayoutsMonth: {},
      balance: undefined,
      payouts: undefined,
    };
  },
  metaInfo() {
    return { title: this.$t("_dashboard.page_title") };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.dashboard-content {
  width: calc(100vw - 140px);
  height: calc(100vh - 120px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0px auto 30px;

  h1 {
    margin-bottom: 5px;
  }

  .title {
    margin-bottom: 15px;
  }

  @media only screen and (min-height: 800px) {
    .horizontal {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .money {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;
      }

      .sneakers {
        min-width: 60%;

        .available_sneakers {
          height: calc(45vh - 2px);
          overflow-y: scroll;
          margin-bottom: 30px;
        }
      }
    }

    .submitted-declarations {
      height: calc(22vh + 4px);
    }
  }

  @media only screen and (min-height: 1200px) {
    .submitted-declarations {
      height: calc(32vh + 5px);
    }

    .available_sneakers {
      height: calc(43vh + 2px) !important;
    }
  }

  @media only screen and (max-height: 800px) {
    .money {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .available_sneakers {
      height: 44vh;
      overflow-y: scroll;
      margin-bottom: 30px;
    }

    .submitted-declarations {
      height: 33vh;
    }
  }

  .declarations {
    .submitted-declarations {
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 10px;

      p {
        text-align: center;
      }
    }
  }

  .available_sneakers {
    p {
      text-align: center;
    }
  }
}
</style>
