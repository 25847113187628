<template>
  <transition name="fade">
    <div v-if="visible" class="alert-content">
      <div class="body">
        <CheckCircle :size="36" v-if="!type" class="check" />
        <CloseCircle :size="36" v-if="type" class="close" />
        <p>{{ message }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
import CheckCircle from "vue-material-design-icons/CheckCircle.vue";
import CloseCircle from "vue-material-design-icons/CloseCircle.vue";

export default {
  name: "Alert",
  components: { CheckCircle, CloseCircle },
  data() {
    return {
      visible: false,
      type: false,
      message: {},
      activeTimer: {},
    };
  },
  methods: {
    startTimer() {
      this.activeTimer = setTimeout(() => {
        this.visible = false;
      }, 2500);
    },
    showCheckMessage(message) {
      this.message = message;
      this.type = false;
      if (this.visible) {
        clearTimeout(this.activeTimer);
      }
      this.visible = true;
      this.startTimer();
    },
    showErrorMessage(message) {
      this.message = message;
      this.type = true;
      if (this.visible) {
        clearTimeout(this.activeTimer);
      }
      this.visible = true;
      this.startTimer();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-content {
  box-sizing: border-box;
  width: 350px;
  height: 90px;
  padding: 25px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: absolute;
  line-height: 36px;
  right: 30px;
  top: 120px;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  z-index: 2;
}
- p {
  font-size: 14px;
  margin-bottom: 10px;
  cursor: default;
}

.check {
  color: var(--color-green);
  margin-right: 10px;
}

.close {
  color: var(--color-red);
  margin-right: 10px;
}

.body {
  height: 40px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
