<template>
  <router-link tag="a" :to="to">
    <div class="sneaker-content">
      <div class="sneaker">
        <div class="image">
          <img
            v-show="!sneaker.image"
            src="../../../../public/no_image.png"
            width="153"
            height="86"
          />
          <img
            v-show="sneaker.image"
            v-bind:src="sneaker.image"
            width="153"
            height="86"
          />
        </div>
        <div class="sneaker_attributes">
          <h4>{{ sneaker.type }} - {{ sneaker.name }}</h4>
          <div class="attribute">
            <p>{{ $t("_sneakers_create.size") }}: {{ sneaker.size }}</p>
          </div>
        </div>
      </div>
      <div class="aim-price">
        <p>€{{ sneaker.aim_price.toFixed(2) }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Sneaker",
  props: {
    sneaker: {},
    to: {},
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sneaker-content {
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .sneaker {
    display: flex;

    .image {
      margin-right: 60px;
    }

    .sneaker_attributes {
      display: flex;
      flex-direction: column;

      .date {
        color: #aeb3bb;
        margin-bottom: 12px;
      }

      .attribute {
        display: flex;
        margin-top: 4px;

        p {
          font-weight: 400;
          font-size: 12pt;
        }
      }
    }
  }

  .aim-price {
    line-height: 86px;
    margin-right: 20px;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
</style>
