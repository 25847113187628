<template>
  <router-link tag="a" :to="to">
    <div v-show="declaration.length == 5" class="declaration-content">
      <p>{{ declaration[0] }}</p>
      <p>{{ declaration[1] }}</p>
      <p>{{ declaration[2] }}</p>
      <p>€{{ declaration[3] }}</p>
      <p>{{ declaration[4] }}</p>
    </div>
    <div v-show="declaration.length == 4" class="declaration-content">
      <p>{{ declaration[0] }}</p>
      <p>{{ declaration[1] }}</p>
      <p>{{ declaration[2] }}</p>
      <p>€{{ declaration[3] }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Declaration",
  props: {
    declaration: [],
    to: {},
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (min-height: 800px) {
  .declaration-content {
    width: 100%;
  }
}

@media only screen and (max-height: 800px) {
  .declaration-content {
    width: calc(100vw - 140px);
  }
}

.declaration-content {
  box-sizing: border-box;
  height: 60px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    min-width: 225px;
  }
}
a {
  text-decoration: none;
  color: var(--primary-color);
}
</style>
