<template>
  <div>
    <div class="sneaker-update-content">
      <div class="image">
        <v-spinner
          :loading="true"
          v-if="imageLoading"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
        <img
          v-on:click="clickImage"
          v-show="!sneaker.image && !imageLoading && !updatedSneaker.image"
          src="../../../public/no_image.png"
        />
        <img
          v-on:click="clickImage"
          v-show="sneaker.image && !imageLoading && !updatedSneaker.image"
          v-bind:src="sneaker.image"
        />
        <img
          v-on:click="clickImage"
          v-show="!imageLoading && updatedSneaker.image"
          v-bind:src="updatedSneaker.image"
        />
        <input
          ref="image"
          style="display: none"
          type="file"
          v-on:change="setImage"
          accept="image/*"
        />
      </div>
      <div class="update-content">
        <v-header
          v-show="sneaker && !isLoading"
          :to="{ name: 'sneakersdetail', params: { id: id } }"
          :text="
            $t('_sneakers_update.update_sneaker') +
              sneaker.type +
              ' - ' +
              sneaker.name
          "
        />
        <div class="spinner" v-if="isLoading">
          <v-spinner
            :loading="true"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
        </div>
        <form class="form" @submit.prevent="updateSneaker" novalidate>
          <div class="attributes" v-show="!isLoading">
            <div class="row">
              <h3>
                {{ $t("_sneakers_create.name") }}
              </h3>
              <v-input
                ref="sneaker_name"
                type="text"
                v-model="updatedSneaker.name"
                :error="$v.updatedSneaker.name"
                v-on:changeValue="changeName"
              />
              <h3>
                {{ $t("_sneakers_create.date_bought") }}
              </h3>
              <v-input
                ref="sneaker_date_bought"
                type="date"
                v-model="updatedSneaker.date_bought"
                :error="$v.updatedSneaker.date_bought"
                v-on:changeValue="changeDateBought"
              />
              <h3>
                {{ $t("_sneakers_create.size") }}
              </h3>
              <v-input
                ref="sneaker_size"
                type="number"
                min="36"
                max="50"
                step="0.5"
                v-model="updatedSneaker.size"
                :error="$v.updatedSneaker.size"
                v-on:changeValue="changeSize"
              />
              <h3>
                {{ $t("_sneakers_create.money_payed") }}
              </h3>
              <v-input
                ref="sneaker_money_payed"
                type="number"
                min="0"
                step="0.01"
                v-model="updatedSneaker.money_payed"
                :error="$v.updatedSneaker.money_payed"
                v-on:changeValue="changeMoneyPayed"
              />
            </div>
            <div class="row">
              <v-select
                ref="sneaker_type"
                :options="type_options"
                :values="type_options"
                :label="$t('_sneakers_create.type_options')"
                :placeholder="$t('_sneakers_create.type_options_placeholder')"
                v-on:setValue="setSneakerType"
                :error="$v.updatedSneaker.type"
              />
              <h3>
                {{ $t("_sneakers_create.aim_price") }}
              </h3>
              <v-input
                ref="sneaker_aim_price"
                type="number"
                min="0"
                step="0.01"
                v-model="updatedSneaker.aim_price"
                :error="$v.updatedSneaker.aim_price"
                v-on:changeValue="changeAimPrice"
              />
              <v-select
                ref="sneaker_size_type"
                :options="size_types"
                :values="size_types"
                :label="$t('_sneakers_create.size_type')"
                :placeholder="$t('_sneakers_create.size_type_placeholder')"
                v-on:setValue="setSizeType"
                :error="$v.updatedSneaker.size_type"
              />
              <v-select
                ref="sneaker_status"
                :options="status_options"
                :values="status_options"
                :label="$t('_sneakers_create.status')"
                :placeholder="$t('_sneakers_create.status_placeholder')"
                v-on:setValue="setSneakerStatus"
                :error="$v.updatedSneaker.status"
              />
            </div>
            <div class="last_row">
              <h3 v-show="updatedSneaker.status == 'Verkocht'">
                {{ $t("_sneakers_create.sold_for") }}
              </h3>
              <v-input
                ref="sneaker_sold_for"
                v-show="updatedSneaker.status == 'Verkocht'"
                v-on:changeValue="changeSoldFor"
                type="number"
                min="0"
                step="0.01"
                v-model="updatedSneaker.sold_for"
                :error="$v.updatedSneaker.sold_for"
              />
              <h3 v-show="updatedSneaker.status == 'Verkocht'">
                {{ $t("_sneakers_create.date_sold") }}
              </h3>
              <v-input
                ref="sneaker_date_sold"
                v-show="updatedSneaker.status == 'Verkocht'"
                v-on:changeValue="changeDateSold"
                type="date"
                v-model="updatedSneaker.date_sold"
                :error="$v.updatedSneaker.date_sold"
              />
            </div>
          </div>
          <v-button
            v-show="!isLoading"
            :label="$t('_buttons.update')"
            position="mini"
            :isLoading="isUpdating"
            :disabled="isUpdating"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "sneakersupdate",
  props: {
    id: {},
    last: {
      type: Object,
      default() {
        return { name: "sneakers", id: "", lastname: "sneakers" };
      },
    },
  },
  data() {
    return {
      status_options: [
        this.$t("_sneakers_create._status.available"),
        this.$t("_sneakers_create._status.sold"),
        this.$t("_sneakers_create._status.in_transit"),
        this.$t("_sneakers_create._status.reserved"),
        this.$t("_sneakers_create._status.not_payed"),
        this.$t("_sneakers_create._status.in_treatment"),
      ],
      size_types: [
        this.$t("_sneakers_create._size_type.men"),
        this.$t("_sneakers_create._size_type.woman"),
        this.$t("_sneakers_create._size_type.older_kids"),
      ],
      type_options: [
        this.$t("_sneakers_create._type.air_jordan_1_high_og"),
        this.$t("_sneakers_create._type.air_jordan_1_retro_high"),
        this.$t("_sneakers_create._type.air_jordan_1_mid"),
        this.$t("_sneakers_create._type.air_jordan_1_low"),
        this.$t("_sneakers_create._type.air_jordan_4"),
        this.$t("_sneakers_create._type.air_jordan_5"),
        this.$t("_sneakers_create._type.air_jordan_6"),
        this.$t("_sneakers_create._type.air_force_1"),
        this.$t("_sneakers_create._type.air_max_1"),
        this.$t("_sneakers_create._type.air_max_90"),
        this.$t("_sneakers_create._type.air_max_95"),
        this.$t("_sneakers_create._type.air_max_97"),
        this.$t("_sneakers_create._type.dunk_low"),
        this.$t("_sneakers_create._type.dunk_high"),
        this.$t("_sneakers_create._type.sb_dunk_low_pro"),
        this.$t("_sneakers_create._type.sb_dunk_high_pro"),
        this.$t("_sneakers_create._type.yeezy"),
        this.$t("_sneakers_create._type.other"),
      ],
      updatedSneaker: {
        id: this.id,
        name: "",
        date_bought: undefined,
        size: undefined,
        money_payed: undefined,
        type: undefined,
        status: "",
        aim_price: undefined,
        sold_for: undefined,
        image: undefined,
        size_type: undefined,
        date_sold: undefined,
      },
      sneaker: {},
      isLoading: false,
      isUpdating: false,
      imageLoading: false,
      balance: {
        new_expense: 0,
        new_revenue: 0,
        date_bought: "",
      },
    };
  },
  validations: {
    updatedSneaker: {
      name: {
        required,
      },
      size: {
        required,
        minValue: minValue(36),
        maxValue: maxValue(50),
      },
      date_bought: {
        required,
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
      },
      money_payed: {
        required,
        minValue: minValue(0.01),
      },
      type: {
        required,
      },
      status: {
        required,
      },
      size_type: {
        required,
      },
      aim_price: {
        required,
        minValue: minValue(0.01),
      },
      sold_for: {
        minValue: minValue(0.01),
        required: requiredIf(function() {
          return this.updatedSneaker.status == "Verkocht";
        }),
      },
      date_sold: {
        required: requiredIf(function() {
          return this.updatedSneaker.status == "Verkocht";
        }),
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
        checkDateSold: function(value) {
          if (value) {
            return value >= this.updatedSneaker.date_bought;
          }
          return true;
        },
      },
    },
  },
  async created() {
    this.isLoading = true;
    this.$store
      .dispatch("sneakers/fetchSingle", this.id)
      .then((res) => {
        this.sneaker = res.data;
        this.balance.date_bought = this.sneaker.date_bought;

        this.$refs["sneaker_name"].setValue(this.sneaker.name);
        this.$refs["sneaker_date_bought"].setValue(this.sneaker.date_bought);
        this.$refs["sneaker_size"].setValue(this.sneaker.size);
        this.$refs["sneaker_money_payed"].setValue(this.sneaker.money_payed);
        this.$refs["sneaker_aim_price"].setValue(this.sneaker.aim_price);
        this.$refs["sneaker_type"].setValue(this.sneaker.type);
        this.$refs["sneaker_size_type"].setValue(this.sneaker.size_type);
        this.$refs["sneaker_status"].setValue(this.sneaker.status);
        this.updatedSneaker.image = this.sneaker.image;
        if (this.sneaker.sold_for) {
          this.$refs["sneaker_sold_for"].setValue(this.sneaker.sold_for);
        }
        if (this.sneaker.date_sold) {
          this.$refs["sneaker_date_sold"].setValue(this.sneaker.date_sold);
        }
      })
      .catch(() => {
        this.$parent.showErrorMessage(
          this.$t("_errors.something_went_wrong_while_fetching_data")
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    changeName(name) {
      this.updatedSneaker.name = name;
    },
    changeSize(size) {
      this.updatedSneaker.size = size;
    },
    changeMoneyPayed(money_payed) {
      this.updatedSneaker.money_payed = money_payed;
    },
    changeAimPrice(aim_price) {
      this.updatedSneaker.aim_price = aim_price;
    },
    changeSoldFor(sold_for) {
      this.updatedSneaker.sold_for = sold_for;
    },
    setSneakerStatus(status) {
      this.$refs["sneaker_sold_for"].reset();
      this.$refs["sneaker_date_sold"].reset();
      this.updatedSneaker.status = status;
    },
    setSneakerType(type) {
      this.updatedSneaker.type = type;
    },
    setSizeType(type) {
      this.updatedSneaker.size_type = type;
    },
    changeDateSold(value) {
      this.updatedSneaker.date_sold = value;
    },
    changeDateBought(date) {
      this.updatedSneaker.date_bought = date;
    },
    setImage(event) {
      this.imageLoading = true;
      if (event) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          this.updatedSneaker.image = event.target.result;
          this.imageLoading = false;
        };
        reader.onerror = function(error) {
          alert(error);
          this.imageLoading = false;
        };
      }
    },
    clickImage() {
      this.$refs["image"].click();
    },
    updateSneaker() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isUpdating = true;
      this.$store
        .dispatch("sneakers/update", this.updatedSneaker)
        .then(() => {
          if (this.sneaker.date_bought != this.updatedSneaker.date_bought) {
            this.balance.new_expense = parseFloat(
              this.updatedSneaker.money_payed
            );
            this.balance.new_revenue = this.sneaker.sold_for
              ? parseFloat(this.sneaker.sold_for)
              : 0;
            this.balance.date_bought = this.updatedSneaker.date_bought;
            let balance = {};
            if (this.sneaker.status == "Verkocht") {
              balance = {
                new_expense: -parseFloat(this.sneaker.money_payed),
                new_revenue: -parseFloat(this.sneaker.sold_for),
                date_bought: this.sneaker.date_bought,
              };
            }
            if (this.sneaker.status != "Verkocht") {
              balance = {
                new_expense: -parseFloat(this.sneaker.money_payed),
                new_revenue: 0,
                date_bought: this.sneaker.date_bought,
              };
            }
            this.$store
              .dispatch("balance/postBalance", balance)
              .then(() => {
                this.$store
                  .dispatch("payouts/updatePayout", {
                    payout_date: this.sneaker.date_bought,
                  })
                  .catch(() => {
                    this.$parent.showErrorMessage(
                      this.$t(
                        "_errors.something_went_wrong_while_fetching_data"
                      )
                    );
                    this.isUpdating = false;
                  });
              })
              .catch(() => {
                this.$parent.showErrorMessage(
                  this.$t("_errors.something_went_wrong_while_fetching_data")
                );
                this.isUpdating = false;
              });
          }

          if (
            this.sneaker.status != "Verkocht" &&
            this.updatedSneaker.status == "Verkocht"
          ) {
            this.balance.new_revenue += parseFloat(
              this.updatedSneaker.sold_for
            );
            this.balance.date_bought = this.updatedSneaker.date_bought;
          }
          if (
            this.sneaker.status == "Verkocht" &&
            this.updatedSneaker.status != "Verkocht"
          ) {
            this.balance.new_revenue -= this.sneaker.sold_for;
          }
          if (
            this.sneaker.money_payed != this.updatedSneaker.money_payed &&
            this.sneaker.date_bought == this.updatedSneaker.date_bought
          ) {
            this.balance.new_expense +=
              this.updatedSneaker.money_payed - this.sneaker.money_payed;
          }
          if (
            this.sneaker.sold_for != this.updatedSneaker.sold_for &&
            this.sneaker.sold_for &&
            this.updatedSneaker.sold_for &&
            this.sneaker.date_bought == this.updatedSneaker.date_bought
          ) {
            this.balance.new_revenue +=
              this.updatedSneaker.sold_for - this.sneaker.sold_for;
          }
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.updatedSneaker.date_bought,
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                  this.isUpdating = false;
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
              this.isUpdating = false;
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isUpdating = false;
        })
        .finally(() => {
          this.$router.push({
            name: "sneakersdetail",
            params: { id: this.updatedSneaker.id, last: this.last },
          });
          this.$parent.showCheckMessage(
            this.$t("_sneakers_update.updated_success")
          );
          this.$v.$reset();
          this.isUpdating = false;
        });
    },
  },
  metaInfo() {
    return { title: this.$t("_sneakers_update.page_title") };
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-height: 800px) {
  .image img {
    width: 688px;
    height: 387px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .image img {
    width: 450px;
    height: 253px;
  }

  .attributes {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 612px;
    height: calc(100vh - 235px);
  }
}

.sneaker-update-content {
  display: flex;
  margin-bottom: 40px;

  .image {
    margin: 0px 60px 0px 0px;
    cursor: pointer;
  }

  .spinner {
    min-width: 710px;
  }

  .update-content {
    .form {
      margin-bottom: 40px;

      h3 {
        margin: 0px 0px 5px 21px;
      }

      v-input {
        max-height: 12px;
      }

      .input-holder {
        margin-bottom: 30px;
      }

      .select-content,
      .input-holder {
        max-width: 256px;
      }
    }
  }

  .attributes {
    display: flex;

    .row {
      min-width: 256px;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
    }

    .last_row {
      min-width: 256px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
