<template>
  <div class="balance-content">
    <div class="balance">
      <div class="balance-property">
        <h3>{{ $t("_dashboard.revenue") }}</h3>
        <p>€{{ balance.revenue.toFixed(2) }}</p>
      </div>
      <div class="balance-property">
        <h3>{{ $t("_dashboard.costs") }}</h3>
        <p>€{{ balance.expense.toFixed(2) }}</p>
      </div>
      <div class="balance-property">
        <h3>{{ $t("_dashboard.profit") }}</h3>
        <p>
          €{{
            balance.expense > 0
              ? (balance.revenue - balance.expense).toFixed(2)
              : (balance.revenue + balance.expense).toFixed(2)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Balance",
  props: {
    balance: {},
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.balance-content {
  box-sizing: border-box;
  width: 100%;
  height: 125px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .balance {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .balance-property {
      margin: auto 0px;
    }

    h3 {
      margin-bottom: 5px;
    }
  }
}
</style>
