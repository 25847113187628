import AppAPI from "../../api/app";
import store from "../../store";
require("dotenv").config();

/* eslint-disable no-empty-pattern*/

export const state = {
  list: [],
};

export const getters = {
  list: (state) => state.list,
  single: (state) => (id) => {
    return store.findItem(state.list, id);
  },
};

export const actions = {
  async fetchBalanceMonths({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("balance/getLastBalances")
        .then(function(response) {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchBalance({ commit }, month) {
    return new Promise((resolve, reject) => {
      AppAPI.get("balance/" + month.year + "/" + month.month)
        .then(function(response) {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchTotalExpenses({}, id) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers/" + id + "/totalExpenses")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async postBalance({ commit }, balance) {
    return new Promise((resolve, reject) => {
      AppAPI.post("balance/", balance)
        .then(function(response) {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export const mutations = {
  SET(state, list) {
    state.list = list;
  },
  UPDATE(state, single) {
    this.updateItem(state.list, single);
  },
  DELETE(state, single) {
    this.deleteItem(state.list, single);
  },
};
