<template>
  <div class="select-content">
    <h3>{{ label }}</h3>
    <select v-model="selected" v-on:change="onChange()">
      <option value="">{{ placeholder }}</option>
      <option v-for="value in values" :key="value" :value="value">{{
        options[values.indexOf(value)]
      }}</option>
    </select>
    <div
      class="input-wrong"
      v-if="
        error != null &&
          error.$error &&
          error.required != null &&
          !error.required
      "
    >
      {{ $t("_validation.field_required") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "VSelect",
  data() {
    return {
      selected: "",
    };
  },
  props: {
    options: [],
    values: [],
    placeholder: {},
    label: {},
    error: {},
  },
  methods: {
    setValue(value) {
      this.selected = value;
      this.$emit("setValue", this.selected);
    },
    onChange() {
      if (this.selected != "") {
        this.$emit("setValue", this.selected);
      } else {
        this.$emit("setValue", undefined);
      }
    },
    reset() {
      this.selected = "";
      this.$emit("setValue", undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 100%;
  height: 44px;
  padding: 2px 20px;
  display: inline-block;
  border-color: var(--border-color);
  border-style: solid;
  border-width: 2px;
  border-radius: 12px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  -webkit-appearance: none;
  background-position-x: 215px;
  margin-bottom: 30px;
  font-size: 14px;
}

select:focus {
  outline: none !important;
  border-color: var(--border-color);
}

h3 {
  margin: 0px 0px 5px 21px;
}

.input-wrong {
  color: var(--input-error-color);
  letter-spacing: var(--input-error-letter-spacing);
  font-size: var(--input-error-font-size);
  margin-top: -25px;
  margin-left: 2px;
  margin-bottom: 10px;
  text-align: right;
}
</style>
