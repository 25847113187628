var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:(_vm.color == null ? '' : _vm.color) +
      ' ' +
      (_vm.size == null ? '' : _vm.size) +
      ' ' +
      _vm.position +
      ' ' +
      (_vm.active ? 'active' : '') +
      ' ' +
      (_vm.label === null ? 'icon-only' : '') +
      ' ' +
      (_vm.secondary ? 'secondary-btn' : ''),attrs:{"disabled":_vm.active || _vm.disabled},on:{"click":_vm.clickHandle}},[(_vm.icon)?_c('span',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(!_vm.isLoading)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.isLoading)?_c('v-spinner',{attrs:{"loading":true}}):_vm._e(),_vm._t("default"),_c('div',{staticClass:"lds-ellipsis",class:{ blue: _vm.secondary }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }