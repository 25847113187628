<template>
  <div class="payouts-content">
    <div class="payouts">
      <div
        class="payouts-property"
        v-for="payout in payouts"
        :key="payout.user"
      >
        <h3>{{ payout.name }}</h3>
        <p>€{{ payout.amount.toFixed(2) }}</p>
        <p class="percentage">({{ payout.percentage }}%)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payouts",
  props: {
    payouts: {},
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.payouts-content {
  box-sizing: border-box;
  width: 100%;
  height: 125px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border-color: var(--sidebar-border-color);
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .payouts {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .payouts-property {
      margin: auto 0px;
      text-align: center;

      p {
        margin-bottom: 5px;
      }

      .percentage {
        font-weight: 200;
      }
    }

    h3 {
      margin-bottom: 10px;
    }
  }
}
</style>
