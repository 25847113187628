<template>
  <div class="headers">
    <h3 v-for="header in headers" :key="header">{{ header }}</h3>
  </div>
</template>

<script>
export default {
  name: "Headers",
  props: {
    headers: [],
  },
};
</script>

<style lang="scss" scoped>
.headers {
  display: flex;
  justify-content: space-between;
  margin: 0px 22px 10px;
}

h3 {
  min-width: 225px;
}
</style>
