<template>
  <div class="declaration-detail-content">
    <v-header
      v-show="declaration.reason != 'Overige' && !isLoading"
      :to="{ name: last.name, params: { id: last.id, last: last } }"
      :text="$t('_declarations.detail') + declaration.reason"
    />
    <v-header
      v-show="declaration.reason == 'Overige' && !isLoading"
      :to="{ name: last.name, params: { id: last.id, last: last } }"
      :text="$t('_declarations_detail.page_title')"
    />
    <v-spinner
      :loading="true"
      v-if="isLoading"
      :color="'#3e3e3e'"
      :size="'30px'"
    ></v-spinner>
    <div class="detail-content">
      <h4 class="date" v-show="!isLoading">{{ declaration.date }}</h4>
      <div class="attribute">
        <h3 class="attribute-title">Type</h3>
        <p v-if="declaration.type">{{ declaration.type }}</p>
      </div>
      <div class="attribute">
        <h3 class="attribute-title">Bedrag</h3>
        <p v-if="declaration.amount">€{{ declaration.amount.toFixed(2) }}</p>
      </div>
      <div class="attribute">
        <h3 class="attribute-title">Status</h3>
        <p v-if="declaration.status">{{ declaration.status }}</p>
      </div>
      <div class="buttons">
        <v-button
          :label="$t('_buttons.update')"
          position="mini"
          v-on:click.native="updateDeclaration"
        />
        <v-button
          :label="$t('_buttons.delete')"
          :secondary="true"
          position="mini"
          v-on:click.native="deleteDeclaration"
          :isLoading="isDeleting"
          :disabled="isDeleting"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "declarationsdetail",
  props: {
    id: {},
    last: {
      type: Object,
      default() {
        return { name: "declarations", id: "" };
      },
    },
  },
  data() {
    return {
      declaration: {},
      isLoading: false,
      isDeleting: false,
      balance: {},
    };
  },
  async created() {
    this.isLoading = true;
    this.$store
      .dispatch("declarations/fetchSingle", this.id)
      .then((res) => {
        this.declaration = res.data;
      })
      .catch(() => {
        this.$parent.showErrorMessage(
          this.$t("_errors.something_went_wrong_while_fetching_data")
        );
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    deleteDeclaration() {
      this.isDeleting = true;
      this.$store
        .dispatch("declarations/delete", this.declaration)
        .then(() => {
          this.balance = {
            new_expense: -parseFloat(this.declaration.amount),
            new_revenue: 0,
            date_bought: this.declaration.date_string,
          };
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.declaration.date_string,
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                  this.isDeleting = false;
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
              this.isDeleting = false;
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isDeleting = false;
        })
        .finally(() => {
          this.$router.push(this.last);
          this.$parent.showCheckMessage(
            this.$t("_declarations.deleted_success")
          );
          this.isDeleting = false;
        });
    },
    updateDeclaration() {
      this.$router.push({
        name: "declarationsupdate",
        params: { id: this.declaration.id, last: this.last },
      });
    },
  },
  metaInfo() {
    return { title: this.$t("_declarations_detail.page_title") };
  },
};
</script>

<style lang="scss" scoped>
.declaration-detail-content {
  height: calc(100vh - 90px);
}

.detail-content {
  margin: -30px 0px 0px 50px;

  .date {
    color: #aeb3bb;
    margin-bottom: 20px;
  }

  .attribute {
    margin-bottom: 20px;
  }

  .attribute-title {
    margin-bottom: 5px;
  }

  .buttons {
    display: flex;
    margin-top: 30px;
  }

  button {
    margin-right: 30px;
  }
}
</style>
