import axios from "axios";
import store from "../store/";
require("dotenv").config();

export const appApi = axios.create({ baseURL: process.env.VUE_APP_API_LINK });

appApi.interceptors.request.use((request) => {
  const token = store.getters["authenticate/check"];
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
});

appApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status >= 500) {
        console.log("status 500");
      }
      if (status === 403) {
        console.log("status 403");
      }
      if (status === 401) {
        store.dispatch("authenticate/logout");
      }
      if (status === 422) {
        if (error.response) {
          console.log("status 422");
        }
      }
    }
    return Promise.reject(error);
  }
);

export default appApi;
