var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-holder"},[_c('div',{staticClass:"input-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"require"},[_vm._v("*")]):_vm._e()]),_c('textarea',{class:{ error: _vm.error != null && _vm.error.$error },style:('height: ' + _vm.height),attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.keyup}}),(
      _vm.error != null &&
        _vm.error.$error &&
        _vm.error.required != null &&
        !_vm.error.required
    )?_c('div',{staticClass:"input-wrong"},[_vm._v(" "+_vm._s(_vm.$t("_validation.field_required"))+" ")]):_vm._e(),(
      _vm.error != null &&
        _vm.error.$error &&
        _vm.error.minLength != null &&
        !_vm.error.minLength
    )?_c('div',{staticClass:"input-wrong"},[_vm._v(" "+_vm._s(_vm.$t("_validation.min_length_x", { min: _vm.error.$params.minLength.min }))+" ")]):_vm._e(),(
      _vm.error != null &&
        _vm.error.$error &&
        _vm.error.maxLength != null &&
        !_vm.error.maxLength
    )?_c('div',{staticClass:"input-wrong"},[_vm._v(" "+_vm._s(_vm.$t("_validation.max_length_x", { max: _vm.error.$params.maxLength.max }))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }