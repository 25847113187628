// Vue
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import VueMeta from "vue-meta";
import store from "./store";
import { i18n } from "./lang/index";

import "./components";

// Layouts
import LayoutDefault from "./layouts/default";
import LayoutAuthenticate from "./layouts/authenticate";

// Axios
import Axios from "axios";

Vue.prototype.$http = Axios;

// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Layouts
Vue.component("default-layout", LayoutDefault);
Vue.component("authenticate-layout", LayoutAuthenticate);

Vue.config.productionTip = false;
Vue.use(VueMeta);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
