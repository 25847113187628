<template>
  <div class="page">
    <div class="filter-content">
      <h1>{{ $t("_sneakers.filtering") }}</h1>
      <div class="filters">
        <div class="year">
          <h3>{{ $t("_sneakers.year") }}</h3>
          <v-select
            :options="uniqueYears"
            :values="uniqueYears"
            :placeholder="$t('_sneakers.select_placeholder')"
            v-on:setValue="getUniqueMonths"
          />
        </div>
        <div class="month" v-if="selectedYear">
          <h3>{{ $t("_sneakers.month") }}</h3>
          <div v-if="uniqueMonths.length > 0 && !isLoadingUniqueMonths">
            <v-checkbox
              v-for="month in uniqueMonths"
              :key="month"
              :text="month"
              v-on:changeFilter="changeMonthFilter"
            />
          </div>
          <v-spinner
            class="spinner"
            :loading="true"
            v-if="isLoadingUniqueMonths"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
        </div>
        <div class="status">
          <h3>{{ $t("_sneakers.status") }}</h3>
          <div>
            <v-checkbox
              ref="status"
              v-for="option in statusOptions"
              :key="option"
              :text="option"
              v-on:changeFilter="changeStatus"
            />
          </div>
        </div>
        <div class="sneaker_type">
          <h3>{{ $t("_sneakers.sneaker_type") }}</h3>
          <div v-if="sneakerTypes.length > 0 && !isLoadingSneakerTypes">
            <v-checkbox
              v-for="type in sneakerTypes"
              :key="type"
              :text="type"
              v-on:changeFilter="changeSneakerTypeFilter"
            />
          </div>
          <p v-if="sneakerTypes.length == 0 && !isLoadingSneakerTypes">
            Geen sneakertypes gevonden.
          </p>
        </div>
        <v-spinner
          :loading="true"
          v-if="isLoadingSneakerTypes"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
      </div>
    </div>
    <div class="sneakers">
      <div class="header">
        <h1>{{ $t("_sneakers.sneakers") }}</h1>
        <router-link :to="{ name: 'sneakerscreate' }">
          <v-button :label="$t('_buttons.add')" position="small" />
        </router-link>
      </div>
      <div class="sneakers_container">
        <p v-if="sneakers.length == 0 && !isLoadingSneakers">
          {{ $t("_sneakers.no_sneakers") }}
        </p>
        <v-sneaker
          v-for="sneaker in sneakers"
          :key="sneaker.id"
          :to="{
            name: 'sneakersdetail',
            params: { id: sneaker.id, last: last },
          }"
          :sneaker="sneaker"
        />
        <v-spinner
          :loading="true"
          v-if="isLoadingSneakers"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sneakers",
  data() {
    return {
      sneakers: [],
      isLoading: false,
      isLoadingSneakers: false,
      isLoadingSneakerTypes: false,
      isLoadingUniqueMonths: false,
      uniqueYears: [],
      uniqueMonths: [],
      selectedYear: undefined,
      selectedMonths: [],
      selectedSneakerTypes: [],
      selectedStatuses: [],
      sneakerTypes: [],
      last: { name: "sneakers", id: "", dashboard: false },
      statusOptions: [
        this.$t("_sneakers_create._status.available"),
        this.$t("_sneakers_create._status.sold"),
      ],
    };
  },
  async created() {
    this.getSneakers();
    this.getUniqueYears();
    this.getSneakerTypes();
  },
  methods: {
    getSneakers() {
      this.isLoadingSneakers = true;
      this.$store
        .dispatch("sneakers/fetchSneakers")
        .then((res) => {
          this.sneakers = res.data;
          this.$parent.showCheckMessage(this.$t("_sneakers.fetching_success"));
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneakers = false;
        })
        .finally(() => {
          this.isLoadingSneakers = false;
        });
    },
    getUniqueYears() {
      this.$store
        .dispatch("sneakers/fetchUniqueYears")
        .then((res) => {
          this.uniqueYears = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        });
    },
    getUniqueMonths(year) {
      this.isLoadingUniqueMonths = true;
      this.selectedYear = year;
      this.uniqueMonths = [];
      this.selectedMonths = [];

      this.selectedStatuses = [];
      this.$refs["status"][0].reset();
      this.$refs["status"][1].reset();

      this.selectedSneakerTypes = [];
      if (!this.selectedYear) {
        this.isLoadingUniqueMonths = false;
        this.getSneakers();
        this.getSneakerTypes();
      }
      if (this.selectedYear) {
        this.sneakerTypes = [];
        this.getSneakerTypesByYear(year);
        this.$store
          .dispatch("sneakers/fetchUniqueMonths", this.selectedYear)
          .then((res) => {
            this.uniqueMonths = res.data;
            this.isLoadingUniqueMonths = false;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
        this.isLoading = true;
        this.$store
          .dispatch("sneakers/fetchListByFilter", {
            year: this.selectedYear,
            months: this.selectedMonths,
            types: this.selectedSneakerTypes,
            statuses: this.selectedStatuses,
          })
          .then((res) => {
            this.sneakers = res.data;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
            this.isLoading = false;
          })
          .finally(() => {
            this.$parent.showCheckMessage(
              this.$t("_sneakers.fetching_success")
            );
            this.isLoading = false;
          });
      }
    },
    changeMonthFilter(month, isChecked) {
      this.isLoading = true;
      this.selectedSneakerTypes = [];
      if (!this.selectedMonths.includes(month) && isChecked == true) {
        this.selectedMonths.push(month);
      }
      if (this.selectedMonths.includes(month) && isChecked == false) {
        this.selectedMonths.splice(this.selectedMonths.indexOf(month), 1);
      }
      if (
        !this.selectedYear &&
        this.selectedSneakerTypes.length == 0 &&
        this.selectedStatuses.length == 0
      ) {
        this.getSneakers();
      } else {
        this.$store
          .dispatch("sneakers/fetchListByFilter", {
            year: this.selectedYear,
            months: this.selectedMonths,
            types: this.selectedSneakerTypes,
            statuses: this.selectedStatuses,
          })
          .then((res) => {
            this.sneakers = res.data;
            this.isLoadingSneakerTypes = true;
            this.$store
              .dispatch("sneakers/fetchSneakerTypesByMonth", {
                year: this.selectedYear,
                months: this.selectedMonths,
              })
              .then((res) => {
                this.sneakerTypes = res.data;
                this.isLoadingSneakerTypes = false;
              })
              .catch(() => {
                this.$parent.showErrorMessage(
                  this.$t("_errors.something_went_wrong_while_fetching_data")
                );
                this.isLoadingSneakerTypes = false;
              });
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
            this.isLoading = false;
          })
          .finally(() => {
            this.$parent.showCheckMessage(
              this.$t("_sneakers.fetching_success")
            );
            this.isLoading = false;
          });
      }
    },
    changeSneakerTypeFilter(type, isChecked) {
      this.isLoading = true;
      if (!this.selectedSneakerTypes.includes(type) && isChecked == true) {
        this.selectedSneakerTypes.push(type);
      }
      if (this.selectedSneakerTypes.includes(type) && isChecked == false) {
        this.selectedSneakerTypes.splice(
          this.selectedSneakerTypes.indexOf(type),
          1
        );
      }
      if (
        !this.selectedYear &&
        this.selectedSneakerTypes.length == 0 &&
        this.selectedStatuses.length == 0
      ) {
        this.getSneakers();
      } else {
        this.$store
          .dispatch("sneakers/fetchListByFilter", {
            year: this.selectedYear,
            months: this.selectedMonths,
            types: this.selectedSneakerTypes,
            statuses: this.selectedStatuses,
          })
          .then((res) => {
            this.sneakers = res.data;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
            this.isLoading = false;
          })
          .finally(() => {
            this.$parent.showCheckMessage(
              this.$t("_sneakers.fetching_success")
            );
            this.isLoading = false;
          });
      }
    },
    changeStatus(status, isChecked) {
      this.isLoading = true;
      this.selectedSneakerTypes = [];
      if (!this.selectedStatuses.includes(status) && isChecked == true) {
        this.selectedStatuses.push(status);
      }
      if (this.selectedStatuses.includes(status) && isChecked == false) {
        this.selectedStatuses.splice(this.selectedStatuses.indexOf(status), 1);
      }
      if (
        !this.selectedYear &&
        this.selectedSneakerTypes.length == 0 &&
        this.selectedStatuses.length == 0
      ) {
        this.getSneakers();
        this.getSneakerTypes();
      } else {
        this.$store
          .dispatch("sneakers/fetchListByFilter", {
            year: this.selectedYear,
            months: this.selectedMonths,
            types: this.selectedSneakerTypes,
            statuses: this.selectedStatuses,
          })
          .then((res) => {
            this.getSneakerTypesByFilter();
            this.sneakers = res.data;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
            this.isLoading = false;
          })
          .finally(() => {
            this.$parent.showCheckMessage(
              this.$t("_sneakers.fetching_success")
            );
            this.isLoading = false;
          });
      }
    },
    getSneakerTypes() {
      this.isLoadingSneakerTypes = true;
      this.$store
        .dispatch("sneakers/fetchSneakerTypes")
        .then((res) => {
          this.sneakerTypes = res.data;
          this.isLoadingSneakerTypes = false;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneakerTypes = false;
        });
    },
    getSneakerTypesByFilter() {
      this.isLoadingSneakerTypes = true;
      this.$store
        .dispatch("sneakers/fetchSneakerTypesByFilter", {
          year: this.selectedYear,
          months: this.selectedMonths,
          types: this.selectedSneakerTypes,
          statuses: this.selectedStatuses,
        })
        .then((res) => {
          this.sneakerTypes = res.data;
          this.isLoadingSneakerTypes = false;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneakerTypes = false;
        });
    },
    getSneakerTypesByYear(year) {
      this.isLoadingSneakerTypes = true;
      this.$store
        .dispatch("sneakers/fetchSneakerTypesByMonth", {
          year: year,
          months: this.selectedMonths,
          statuses: this.selectedStatuses,
        })
        .then((res) => {
          this.sneakerTypes = res.data;
          this.isLoadingSneakerTypes = false;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneakerTypes = false;
        });
    },
  },
  metaInfo() {
    return { title: this.$t("_sneakers.page_title") };
  },
};
</script>

<style lang="scss" scoped>
v-spinner {
  margin-top: 60px;
}

::-webkit-scrollbar {
  display: none;
}

.page {
  display: flex;
  width: calc(100vw - 75px - 150px - 65px);

  .filter-content {
    min-width: 250px;

    h1 {
      margin-bottom: 15px;
      line-height: 35px;
    }

    h3 {
      margin-bottom: 10px;
    }

    .month,
    .spinner,
    .status {
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-height: 800px) {
    .sneakers {
      height: calc(100vh - 90px - 147px);
    }

    .filters {
      height: calc(100vh - 90px - 158px);
    }
  }

  @media only screen and (min-height: 1200px) {
    .sneakers {
      height: calc(100vh - 90px - 87px);
    }

    .filters {
      height: calc(100vh - 90px - 107px);
    }
  }

  @media only screen and (max-height: 800px) {
    .sneakers {
      height: calc(100vh - 90px - 127px);
    }

    .filters {
      height: calc(100vh - 90px - 118px);
    }
  }

  .filters {
    overflow-y: scroll;
  }

  .sneakers {
    left: 150px;
    width: 100%;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      line-height: 35px;
      text-align: center;
    }

    .sneakers_container {
      height: 100%;
      overflow-y: scroll;
      margin-bottom: 40px;

      p {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}
</style>
