import Vue from "vue";

import Input from "./form/Input";
import Textarea from "./form/Textarea";
import Button from "./form/Button";
import Select from "./form/Select";
import Checkbox from "./form/Checkbox";
import Spinner from "./ux/Spinner";
import Alert from "./ux/Alert";
import Declaration from "./declarations/Declaration";
import Headers from "./declarations/Headers";
import Sneaker from "./sneakers/Sneaker";
import Header from "./layout/Header.vue";

[
  Input,
  Textarea,
  Button,
  Select,
  Checkbox,
  Spinner,
  Alert,
  Declaration,
  Headers,
  Header,
  Sneaker,
].forEach((Component) => {
  Vue.component(Component.name, Component);
});
