import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Dashboard from "../pages/dashboard/dashboard.vue";
import Sneakers from "../pages/sneakers/sneakers.vue";
import SneakersCreate from "../pages/sneakers/sneakers-create.vue";
import SneakersDetail from "../pages/sneakers/sneakers-detail.vue";
import SneakersUpdate from "../pages/sneakers/sneakers-update.vue";
import Declarations from "../pages/declarations/declarations.vue";
import DeclarationsCreate from "../pages/declarations/declarations-create.vue";
import DeclarationsDetail from "../pages/declarations/declarations-detail.vue";
import DeclarationsUpdate from "../pages/declarations/declarations-update.vue";
import SignIn from "../pages/authentication/sign-in.vue";
import NotFound from "../pages/404.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/sign-in",
      name: "SignIn",
      component: SignIn,
      alias: "/",
      meta: {
        layout: "authenticate",
        requiresVisitor: true,
      },
    },
    {
      path: "/sneakers",
      name: "sneakers",
      component: Sneakers,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sneakers/create",
      name: "sneakerscreate",
      component: SneakersCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sneakers/:id",
      name: "sneakersdetail",
      component: SneakersDetail,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/sneakers/:id/update",
      name: "sneakersupdate",
      component: SneakersUpdate,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/declarations",
      name: "declarations",
      component: Declarations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/declarations/create",
      name: "DeclarationsCreate",
      component: DeclarationsCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/declarations/:id",
      name: "declarationsdetail",
      component: DeclarationsDetail,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/declarations/:id/update",
      name: "declarationsupdate",
      component: DeclarationsUpdate,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["authenticate/check"];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
      return;
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isAuthenticated) {
      next({ name: "dashboard" });
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
