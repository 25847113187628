import AppAPI from "../../api/app";
import store from "../../store";
require("dotenv").config();

/* eslint-disable no-empty-pattern*/

export const state = {
  list: [],
};

export const getters = {
  list: (state) => state.list,
  single: (state) => (id) => {
    return store.findItem(state.list, id);
  },
};

export const actions = {
  async fetchDeclarationsBySneakerId({}, sneakerid) {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations?sneakerId=" + sneakerid)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSubmittedDeclarations({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations?status=Ingediend")
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchDeclarations({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations")
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchUniqueYears() {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations?distinctYears=true")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakerTypes() {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?sneakertypes=true")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchAllowance({}, brand) {
    return new Promise((resolve, reject) => {
      AppAPI.get("allowance?car=" + brand)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSneakersByType({}, type) {
    return new Promise((resolve, reject) => {
      AppAPI.get("sneakers?type=" + type)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchUniqueMonths({}, year) {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations/distinctmonths?year=" + year)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchDeclarationsByFilter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("declarations/" + payload.year, payload)
        .then(function(response) {
          commit("SET", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async fetchSingle({}, id) {
    return new Promise((resolve, reject) => {
      AppAPI.get("declarations/" + id)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.post("declarations", payload)
        .then(function(response) {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.put("declarations/" + payload.id, payload)
        .then(function(response) {
          commit("UPDATE", response.data.id);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AppAPI.delete("declarations/" + payload.id)
        .then(function(response) {
          commit("DELETE", payload);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export const mutations = {
  SET(state, list) {
    state.list = list;
  },
  UPDATE(state, single) {
    this.updateItem(state.list, single);
  },
  DELETE(state, single) {
    this.deleteItem(state.list, single);
  },
};
