<template>
  <div class="page">
    <div class="filters">
      <h1>{{ $t("_declarations.filtering") }}</h1>

      <h3>{{ $t("_declarations.year") }}</h3>
      <v-select
        :options="uniqueYears"
        :values="uniqueYears"
        :placeholder="$t('_declarations.select_placeholder')"
        v-on:setValue="selectYear"
      />

      <div class="month" v-if="selectedYear">
        <h3>
          {{ $t("_declarations.month") }}
        </h3>
        <div v-if="uniqueMonths && !isLoadingUniqueMonths">
          <v-checkbox
            v-for="month in uniqueMonths"
            :key="month"
            :text="month"
            v-on:changeFilter="
              (value, isChecked) =>
                changeFilter('selectedMonths', value, isChecked)
            "
          />
        </div>
        <v-spinner
          class="spinner"
          :loading="true"
          v-if="isLoadingUniqueMonths"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
      </div>

      <h3>{{ $t("_sneakers.status") }}</h3>
      <div>
        <v-checkbox
          ref="status"
          v-for="option in statusOptions"
          :key="option"
          :text="option"
          v-on:changeFilter="
            (value, isChecked) =>
              changeFilter('selectedStatuses', value, isChecked)
          "
        />
      </div>
    </div>

    <div class="declarations">
      <div class="header">
        <h1>{{ $t("_declarations.declarations") }}</h1>
        <router-link :to="{ name: 'DeclarationsCreate' }">
          <v-button :label="$t('_buttons.submit')" position="small" />
        </router-link>
      </div>
      <div class="table">
        <headers :headers="headers" />
        <div class="declaration_container">
          <v-spinner
            class="spinner"
            :loading="true"
            v-if="isLoading"
            :color="'#3e3e3e'"
            :size="'30px'"
          ></v-spinner>
          <p v-if="declarations.length == 0 && !isLoading">
            {{ $t("_declarations.no_declarations") }}
          </p>
          <declaration
            v-for="declaration in declarations"
            :key="declaration.id"
            :to="{
              name: 'declarationsdetail',
              params: { id: declaration.id, last: last },
            }"
            :declaration="[
              declaration.reason,
              declaration.date,
              declaration.type,
              declaration.amount.toFixed(2),
              declaration.status,
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Declarations",
  data() {
    return {
      declarations: [],
      uniqueMonths: null,
      selectedYear: null,
      selectedMonths: [],
      selectedStatuses: [],
      uniqueYears: [],
      isLoading: false,
      isLoadingUniqueMonths: false,
      last: { name: "declarations", id: "" },
      headers: [
        this.$t("_declarations._headers.reason"),
        this.$t("_declarations._headers.date"),
        this.$t("_declarations._headers.type"),
        this.$t("_declarations._headers.amount"),
        this.$t("_declarations._headers.status"),
      ],
      statusOptions: [
        this.$t("_declarations_create._status.submitted"),
        this.$t("_declarations_create._status.payed_out"),
      ],
    };
  },
  async created() {
    this.getDeclarations();
    this.getUniqueYears();
  },
  methods: {
    getDeclarations() {
      this.isLoading = true;
      this.$store
        .dispatch("declarations/fetchDeclarations")
        .then((res) => {
          this.declarations = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        })
        .finally(() => {
          this.$parent.showCheckMessage(
            this.$t("_declarations.fetching_success")
          );
          this.isLoading = false;
        });
    },
    getUniqueYears() {
      this.$store
        .dispatch("declarations/fetchUniqueYears")
        .then((res) => {
          this.uniqueYears = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        });
    },
    getDeclarationsByFilter() {
      this.isLoading = true;
      this.$store
        .dispatch("declarations/fetchDeclarationsByFilter", {
          year: this.selectedYear,
          months: this.selectedMonths,
          statuses: this.selectedStatuses,
        })
        .then((res) => {
          this.declarations = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        })
        .finally(() => {
          this.$parent.showCheckMessage(
            this.$t("_declarations.fetching_success")
          );
          this.isLoading = false;
        });
    },
    async getUniqueMonths() {
      this.isLoadingUniqueMonths = true;
      this.$store
        .dispatch("declarations/fetchUniqueMonths", this.selectedYear)
        .then((res) => {
          this.uniqueMonths = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        })
        .finally(() => {
          this.isLoadingUniqueMonths = false;
        });
    },
    selectYear(year) {
      this.selectedYear = year;
      this.resetSelectedFilters();

      if (!this.selectedYear) {
        this.getDeclarations();
      } else {
        this.getUniqueMonths().then(() => {
          this.getDeclarationsByFilter();
        });
      }
    },
    changeFilter(type, value, isChecked) {
      if (!this.[type].includes(value) && isChecked == true) {
        this.[type].push(value);
      }
      if (this.[type].includes(value) && isChecked == false) {
        this.[type].splice(this.[type].indexOf(value), 1);
      }
      this.getDeclarationsByFilter();
    },
    resetSelectedFilters() {
      this.uniqueMonths = [];
      this.selectedMonths = [];
      this.selectedStatuses = [];
      this.$refs["status"][0].reset();
      this.$refs["status"][1].reset();
    },
  },
  metaInfo() {
    return { title: this.$t("_declarations.page_title") };
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;

  .filters {
    width: 250px;
    position: relative;
    overflow-y: scroll;

    h1 {
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 10px;
    }

    .month,
    .spinner {
      margin-bottom: 30px;
    }
  }

  .declarations {
    left: 150px;
    width: calc(100% - 250px);
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      line-height: 35px;
    }

    p {
      text-align: center;
      margin-top: 30px;
    }
  }
}

@media only screen and (min-height: 800px) {
  .page {
    width: 91%;

    .declaration_container {
      height: calc(100vh - 90px - 147px);
      overflow: scroll;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (min-height: 1200px) {
  .page {
    width: 95%;

    .declaration_container {
      height: calc(100vh - 90px - 157px);
    }
  }
}

@media only screen and (max-height: 800px) {
  .page {
    width: 89%;

    .declarations {
      width: calc(90% - 250px) !important;
    }

    .table {
      overflow: scroll;
      height: calc(100vh - 180px);
    }

    .filters {
      height: calc(100vh - 150px);
    }

    .filters::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
