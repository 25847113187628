import AppAPI from "../../api/app";
import Cookies from "js-cookie";
require("dotenv").config();
import router from "../../router/router";

export const state = {
  user: {},
  token: Cookies.get("token"),
  userId: Cookies.get("user"),
};

export const getters = {
  user: (state) => {
    state.user;
  },
  check: (state) => state.token,
};

export const actions = {
  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      AppAPI.post("login", user)
        .then(function(response) {
          commit("UPDATE", response.data.user);
          commit("SAVE_TOKEN", response.data.token);
          commit("SAVE_USER", response.data.user);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      AppAPI.get("users/" + state.userId)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          commit("LOGOUT");
          reject(error);
        });
    });
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("LOGOUT");
      router.push({
        name: "SignIn",
      });
      resolve();
    });
  },
};

export const mutations = {
  UPDATE(state, single) {
    state.user = single;
  },
  SAVE_TOKEN(state, token) {
    state.token = token;
    var in1h = 1 / 24;
    Cookies.set("token", token, { expires: in1h });
  },
  SAVE_USER(state, user) {
    state.userId = user;
    var in1h = 1 / 24;
    Cookies.set("user", user, { expires: in1h });
  },
  LOGOUT(state) {
    state.user = {};
    state.token = null;
    state.userId = null;
    Cookies.remove("token");
    Cookies.remove("user");
  },
};
