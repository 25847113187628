<template>
  <div class="declaration-update-content">
    <v-header
      v-show="declaration.reason != 'Overige' && !isLoading"
      :to="{ name: 'declarationsdetail', params: { id: id } }"
      :text="$t('_declarations.update_for') + declaration.reason"
    />
    <v-header
      v-show="declaration.reason == 'Overige' && !isLoading"
      :to="{ name: 'declarationsdetail', params: { id: id } }"
      :text="$t('_declarations.update')"
    />
    <v-spinner
      :loading="true"
      v-if="isLoading"
      :color="'#3e3e3e'"
      :size="'30px'"
    ></v-spinner>
    <form class="form" @submit.prevent="updateDeclaration" novalidate>
      <v-select
        ref="declaration_type"
        :options="declaration_types"
        :values="declaration_types"
        :label="$t('_declarations_create.declaration_type')"
        :placeholder="$t('_declarations_create.declaration_type_placeholder')"
        v-on:setValue="setDeclarationType"
        :error="$v.updatedDeclaration.type"
      />
      <h3 v-show="updatedDeclaration.type == 'Reiskosten'">
        {{ $t("_declarations_create.amount_of_km") }}
      </h3>
      <v-input
        ref="declaration_distance"
        v-show="updatedDeclaration.type == 'Reiskosten'"
        type="number"
        v-model="updatedDeclaration.distance"
        :error="$v.updatedDeclaration.distance"
        min="0"
        step="0.1"
        v-on:changeValue="changeDistance"
      />
      <v-select
        ref="declaration_car"
        v-show="
          updatedDeclaration.type == 'Reiskosten' && updatedDeclaration.distance
        "
        :options="car_types"
        :values="car_types"
        :label="$t('_declarations_create.select_car')"
        :placeholder="$t('_declarations_create.select_car_placeholder')"
        v-on:setValue="setCar"
        :error="$v.updatedDeclaration.car_brand"
      />
      <v-select
        ref="declaration_isSneaker"
        v-show="
          (updatedDeclaration.type &&
            updatedDeclaration.type != 'Reiskosten') ||
            (updatedDeclaration.type &&
              updatedDeclaration.type == 'Reiskosten' &&
              updatedDeclaration.car_brand)
        "
        :options="declaration_for_options"
        :values="declaration_for_options"
        :label="$t('_declarations_create.is_sneaker')"
        :placeholder="$t('_declarations_create.is_sneaker_placeholder')"
        :error="$v.declarationFor"
        v-on:setValue="setDeclarationFor"
      />
      <v-select
        ref="declaration_sneaker_type"
        v-show="declarationFor == 'Ja'"
        :options="sneakertypes"
        :values="sneakertypes"
        :label="$t('_declarations_create.sneaker_type')"
        :placeholder="$t('_declarations_create.sneaker_type_placeholder')"
        v-on:setValue="setSneakerType"
        :error="$v.updatedDeclaration.sneaker_type"
      />
      <v-select
        ref="sneaker"
        v-show="declarationFor == 'Ja' && updatedDeclaration.sneaker_type"
        :options="sneaker_names"
        :values="sneaker_ids"
        :label="$t('_declarations_create.sneaker')"
        :placeholder="$t('_declarations_create.sneaker_placeholder')"
        v-on:setValue="setSneakerId"
        :error="$v.updatedDeclaration.sneakerId"
      />
      <div class="general" v-show="showGeneral">
        <h3>
          {{ $t("_declarations_create.amount") }}
        </h3>
        <v-input
          ref="declaration_amount"
          :disabled="updatedDeclaration.type == 'Reiskosten'"
          type="number"
          step="0.01"
          min="0"
          v-model="updatedDeclaration.amount"
          v-on:changeValue="changeAmount"
          :error="$v.updatedDeclaration.amount"
        />
        <h3>
          {{ $t("_declarations_create.date") }}
        </h3>
        <v-input
          ref="declaration_date"
          type="date"
          :error="$v.updatedDeclaration.date_string"
          v-model="updatedDeclaration.date_string"
          v-on:changeValue="changeDate"
        />
        <v-select
          ref="declaration_status"
          :options="status_options"
          :values="status_options"
          :label="$t('_declarations_create.status')"
          :placeholder="$t('_declarations_create.status_placeholder')"
          v-on:setValue="setStatus"
          :error="$v.updatedDeclaration.status"
        />
      </div>
      <v-button
        :isLoading="isUpdating"
        :disabled="isUpdating"
        v-show="showGeneral"
        :label="$t('_buttons.update')"
        position="small"
      />
    </form>
  </div>
</template>

<script>
import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "declarationsupdate",
  props: {
    id: {},
    last: {
      type: Object,
      default() {
        return { name: "declarations", id: "" };
      },
    },
  },
  data() {
    return {
      declaration: {},
      isLoading: false,
      isUpdating: false,
      declaration_for_options: [
        this.$t("_declarations_create._answer.yes"),
        this.$t("_declarations_create._answer.no"),
      ],
      status_options: [
        this.$t("_declarations_create._status.submitted"),
        this.$t("_declarations_create._status.payed_out"),
      ],
      car_types: [
        this.$t("_declarations_create._car.mercedes"),
        this.$t("_declarations_create._car.up"),
      ],
      declaration_types: [
        this.$t("_declarations_create._type.purchase"),
        this.$t("_declarations_create._type.food"),
        this.$t("_declarations_create._type.meal_allowance"),
        this.$t("_declarations_create._type.driving_costs"),
        this.$t("_declarations_create._type.other"),
      ],
      updatedDeclaration: {
        id: this.id,
        type: "",
        status: "",
        date_string: "",
        amount: undefined,
        sneakerId: undefined,
        distance: undefined,
        car_brand: undefined,
        sneaker_type: undefined,
      },
      declarationFor: {},
      kilometer_allowance: {},
      sneakertypes: [],
      sneaker_names: [],
      sneaker_ids: [],
      showGeneral: false,
      balance: {},
    };
  },
  validations: {
    updatedDeclaration: {
      type: {
        required,
      },
      status: {
        required,
      },
      date_string: {
        required,
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
      },
      amount: {
        required,
        minValue: minValue(0.01),
      },
      sneakerId: {
        required: requiredIf(function() {
          return this.declarationFor == "Ja";
        }),
      },
      distance: {
        minValue: minValue(0.01),
        required: requiredIf(function() {
          return this.updatedDeclaration.type == "Reiskosten";
        }),
      },
      car_brand: {
        required: requiredIf(function() {
          return this.updatedDeclaration.type == "Reiskosten";
        }),
      },
      sneaker_type: {
        required: requiredIf(function() {
          return this.declarationFor == "Ja";
        }),
      },
    },
    declarationFor: {
      required,
    },
  },
  async created() {
    this.isLoading = true;
    this.$store
      .dispatch("declarations/fetchSingle", this.id)
      .then((res) => {
        this.declaration = res.data;
        this.$refs["declaration_type"].setValue(this.declaration.type);
        if (this.declaration.distance) {
          this.$refs["declaration_distance"].setValue(
            this.declaration.distance
          );
        }
        if (this.declaration.car_brand) {
          this.$refs["declaration_car"].setValue(this.declaration.car_brand);
        }
        this.$refs["declaration_isSneaker"].setValue(
          this.declaration.sneakerid
            ? this.$t("_declarations_create._answer.yes")
            : this.$t("_declarations_create._answer.no")
        );
        if (this.declaration.sneaker_type) {
          this.$refs["declaration_sneaker_type"].setValue(
            this.declaration.sneaker_type
          );
        }
        if (this.declaration.sneakerid) {
          this.$refs["sneaker"].setValue(this.declaration.sneakerid);
        }
        this.$refs["declaration_amount"].setValue(this.declaration.amount);
        this.$refs["declaration_date"].setValue(this.declaration.date_string);
        this.$refs["declaration_status"].setValue(this.declaration.status);
      })
      .catch(() => {
        this.$parent.showErrorMessage(
          this.$t("_errors.something_went_wrong_while_fetching_data")
        );
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    setDeclarationFor(declarationFor) {
      this.declarationFor = declarationFor;
      this.showGeneral = false;
      if (this.declarationFor == "Ja") {
        this.$store
          .dispatch("declarations/fetchSneakerTypes")
          .then((res) => {
            this.sneakertypes = res.data;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
      if (this.declarationFor == "Nee") {
        this.$refs["declaration_sneaker_type"].reset();
        this.$refs["sneaker"].reset();
        this.showGeneral = true;
      }
    },
    setStatus(status) {
      this.updatedDeclaration.status = status;
    },
    changeAmount(amount) {
      this.updatedDeclaration.amount = amount;
    },
    setSneakerType(sneakerType) {
      this.sneaker_ids = [];
      this.sneaker_names = [];

      this.updatedDeclaration.sneaker_type = sneakerType;
      if (this.updatedDeclaration.sneaker_type != undefined) {
        this.$store
          .dispatch(
            "declarations/fetchSneakersByType",
            this.updatedDeclaration.sneaker_type
          )
          .then((res) => {
            res.data.forEach((sneaker) => {
              this.sneaker_names.push(sneaker.name);
              this.sneaker_ids.push(sneaker.id);
            });
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    setDeclarationType(type) {
      this.updatedDeclaration.type = type;
      this.showGeneral = false;

      this.$refs["declaration_amount"].reset();
      this.$refs["declaration_isSneaker"].reset();
      this.$refs["declaration_sneaker_type"].reset();
      this.$refs["sneaker"].reset();

      if (this.updatedDeclaration.type != "Reiskosten") {
        this.$refs["declaration_distance"].reset();
        this.$refs["declaration_car"].reset();
      }
    },
    changeDate(date) {
      this.updatedDeclaration.date_string = date;
    },
    setSneakerId(sneakerId) {
      this.updatedDeclaration.sneakerId = sneakerId;
      if (sneakerId) {
        this.showGeneral = true;
      }
    },
    changeDistance(distance) {
      this.updatedDeclaration.distance = distance;
      if (this.updatedDeclaration.car_brand) {
        this.$refs["declaration_amount"].reset();
        this.$store
          .dispatch(
            "declarations/fetchAllowance",
            this.updatedDeclaration.car_brand
          )
          .then((res) => {
            this.kilometer_allowance = res.data;
            this.updatedDeclaration.amount = (
              this.kilometer_allowance * this.updatedDeclaration.distance
            ).toFixed(2);
            this.$refs["declaration_amount"].setValue(
              this.updatedDeclaration.amount
            );
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    setCar(car) {
      this.updatedDeclaration.car_brand = car;
      if (this.updatedDeclaration.car_brand) {
        this.$refs["declaration_amount"].reset();
        this.$store
          .dispatch(
            "declarations/fetchAllowance",
            this.updatedDeclaration.car_brand
          )
          .then((res) => {
            this.kilometer_allowance = res.data;
            this.updatedDeclaration.amount = (
              this.kilometer_allowance * this.updatedDeclaration.distance
            ).toFixed(2);
            this.$refs["declaration_amount"].setValue(
              this.updatedDeclaration.amount
            );
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    updateDeclaration() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isUpdating = true;
      this.$store
        .dispatch("declarations/update", this.updatedDeclaration)
        .then(() => {
          if (
            this.declaration.date_string != this.updatedDeclaration.date_string
          ) {
            this.balance = {
              new_expense: parseFloat(this.updatedDeclaration.amount),
              new_revenue: 0,
              date_bought: this.updatedDeclaration.date_string,
            };
            let balance = {
              new_expense: -parseFloat(this.declaration.amount),
              new_revenue: 0,
              date_bought: this.declaration.date_string,
            };
            this.$store
              .dispatch("balance/postBalance", balance)
              .then(() => {
                this.$store
                  .dispatch("payouts/updatePayout", {
                    payout_date: this.declaration.date_string,
                  })
                  .catch(() => {
                    this.$parent.showErrorMessage(
                      this.$t(
                        "_errors.something_went_wrong_while_fetching_data"
                      )
                    );
                    this.isUpdating = false;
                  });
              })
              .catch(() => {
                this.$parent.showErrorMessage(
                  this.$t("_errors.something_went_wrong_while_fetching_data")
                );
                this.isUpdating = false;
              });
          }
          if (
            this.declaration.date_string == this.updatedDeclaration.date_string
          ) {
            this.balance = {
              new_expense: parseFloat(
                this.updatedDeclaration.amount - this.declaration.amount
              ),
              new_revenue: 0,
              date_bought: this.updatedDeclaration.date_string,
            };
          }
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.updatedDeclaration.date_string,
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                  this.isUpdating = false;
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
              this.isUpdating = false;
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isUpdating = false;
        })
        .finally(() => {
          this.$router.push({
            name: "declarationsdetail",
            params: { id: this.updatedDeclaration.id, last: this.last },
          });
          this.$parent.showCheckMessage(
            this.$t("_declarations.updated_success")
          );
          this.$v.$reset();
          this.isUpdating = false;
        });
    },
  },
  metaInfo() {
    return { title: this.$t("_declarations_detail.page_title") };
  },
};
</script>

<style lang="scss" scoped>
.declaration-update-content {
  height: calc(100vh - 90px);
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-height: 800px) {
  .form {
    height: calc(100vh - 90px - 157px);
  }
}

@media only screen and (max-height: 800px) {
  .form {
    height: calc(100vh - 90px - 142px);
  }
}

.form {
  overflow-y: scroll;
  margin-left: 50px;

  .select-content,
  .input-holder {
    max-width: 256px;
  }

  .input-holder {
    margin-bottom: 30px;
  }

  h3 {
    margin-left: 21px;
  }
}
</style>
