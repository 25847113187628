<template>
  <div class="sneaker_detail">
    <div class="sneaker-detail-content">
      <div class="image">
        <img v-show="!sneaker.image" src="../../../public/no_image.png" />
        <img v-show="sneaker.image" v-bind:src="sneaker.image" />
      </div>
      <div class="detail-content">
        <v-header
          v-show="!isLoadingSneaker"
          :to="
            last.name == 'sneakersdetail'
              ? { name: last.lastname }
              : { name: last.name, params: { id: last.id } }
          "
          :text="sneaker.type + ' - ' + sneaker.name"
        />
        <v-spinner
          :loading="true"
          v-if="isLoadingSneaker"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
        <h4 class="date" v-show="!isLoadingSneaker">
          {{ sneaker.date_bought_formatted }}
        </h4>
        <div class="attributes">
          <div class="row">
            <div v-if="sneaker.type" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.type") }}
              </h3>
              <p>{{ sneaker.type }}</p>
            </div>
            <div v-if="sneaker.size" class="attribute">
              <h3 class="attribute-title">{{ $t("_sneakers_detail.size") }}</h3>
              <p>{{ sneaker.size }}</p>
            </div>
            <div v-if="sneaker.money_payed" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.money_payed") }}
              </h3>
              <p>€{{ sneaker.money_payed.toFixed(2) }}</p>
            </div>
            <div v-if="sneaker.status" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.status") }}
              </h3>
              <p>{{ sneaker.status }}</p>
            </div>
          </div>
          <div class="row">
            <div v-if="sneaker.last_edited" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.sizing_type") }}
              </h3>
              <p>
                {{ sneaker.size_type }}
              </p>
            </div>
            <div v-if="sneaker.aim_price" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.aim_price") }}
              </h3>
              <p>€{{ sneaker.aim_price.toFixed(2) }}</p>
            </div>
            <div v-if="sneaker.status == 'Verkocht'" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.date_sold") }}
              </h3>
              <p>{{ sneaker.date_sold_formatted }}</p>
            </div>
            <div v-if="sneaker.status == 'Verkocht'" class="attribute">
              <h3 class="attribute-title">
                {{ $t("_sneakers_detail.sold_for") }}
              </h3>
              <p>€{{ sneaker.sold_for.toFixed(2) }}</p>
            </div>
          </div>
        </div>
        <div class="buttons" v-show="!isLoadingSneaker">
          <v-button
            :label="$t('_buttons.update')"
            position="mini"
            v-on:click.native="updateSneaker"
          />
          <v-button
            :label="$t('_buttons.delete')"
            :secondary="true"
            position="mini"
            v-on:click.native="deleteSneaker"
            :isLoading="isDeleting"
            :disabled="isDeleting"
          />
        </div>
      </div>
    </div>
    <div class="declarations">
      <h1>{{ $t("_declarations.submitted_declarations") }}</h1>
      <headers :headers="headers" />
      <div class="declarations_container">
        <v-spinner
          :loading="true"
          v-if="isLoadingDeclarations"
          :color="'#3e3e3e'"
          :size="'30px'"
        ></v-spinner>
        <p v-if="declarations.length == 0 && !isLoadingDeclarations">
          {{ $t("_declarations.no_declarations") }}
        </p>
        <declaration
          v-for="declaration in declarations"
          :key="declaration.id"
          :to="{
            name: 'declarationsdetail',
            params: { id: declaration.id, last: newLast },
          }"
          :declaration="[
            declaration.type,
            declaration.date,
            declaration.status,
            declaration.amount.toFixed(2),
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sneakersdetail",
  props: {
    id: {},
    last: {
      type: Object,
      default() {
        return { name: "sneakers", id: "", lastname: "sneakers" };
      },
    },
  },
  data() {
    return {
      sneaker: {},
      isLoadingSneaker: false,
      isDeleting: false,
      isLoadingDeclarations: false,
      headers: [
        this.$t("_declarations._headers.type"),
        this.$t("_declarations._headers.date"),
        this.$t("_declarations._headers.status"),
        this.$t("_declarations._headers.amount"),
      ],
      declarations: [],
      newLast: {
        name: "sneakersdetail",
        id: this.id,
        lastname: this.last.name,
      },
      balance: {},
      totalExpenses: 0,
    };
  },
  async created() {
    this.fetchSingle();
    this.getDeclarations();
  },
  methods: {
    fetchSingle() {
      this.isLoadingSneaker = true;
      this.$store
        .dispatch("sneakers/fetchSingle", this.id)
        .then((res) => {
          this.sneaker = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingSneaker = false;
        })
        .finally(() => {
          this.isLoadingSneaker = false;
        });
    },
    getDeclarations() {
      this.isLoadingDeclarations = true;
      this.$store
        .dispatch("declarations/fetchDeclarationsBySneakerId", this.id)
        .then((res) => {
          this.declarations = res.data;
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isLoadingDeclarations = false;
        })
        .finally(() => {
          this.isLoadingDeclarations = false;
        });
    },
    deleteSneaker() {
      this.isDeleting = true;
      this.$store
        .dispatch("balance/fetchTotalExpenses", this.sneaker.id)
        .then((res) => {
          this.totalExpenses = res.data.total;

          if (this.sneaker.status != "Verkocht") {
            this.balance = {
              new_expense:
                -parseFloat(this.sneaker.money_payed) - this.totalExpenses,
              new_revenue: 0,
              date_bought: this.sneaker.date_bought,
            };
          }
          if (this.sneaker.status == "Verkocht") {
            this.balance = {
              new_expense:
                -parseFloat(this.sneaker.money_payed) - this.totalExpenses,
              new_revenue: -parseFloat(this.sneaker.sold_for),
              date_bought: this.sneaker.date_bought,
            };
          }
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.sneaker.date_bought,
                })
                .then(() => {
                  this.$store
                    .dispatch("sneakers/delete", this.sneaker)
                    .then(() => {
                      this.$router.push(this.last);
                      this.$parent.showCheckMessage(
                        this.$t("_sneakers.deleted_success")
                      );
                      this.isDeleting = false;
                    })
                    .catch(() => {
                      this.$parent.showErrorMessage(
                        this.$t(
                          "_errors.something_went_wrong_while_fetching_data"
                        )
                      );
                      this.isDeleting = false;
                    });
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                  this.isDeleting = false;
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
              this.isDeleting = false;
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
          this.isDeleting = false;
        });
    },
    updateSneaker() {
      this.$router.push({
        name: "sneakersupdate",
        params: { id: this.sneaker.id, last: this.last },
      });
    },
  },
  metaInfo() {
    return { title: this.$t("_sneakers.page_title") };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-height: 800px) {
  .image img {
    width: 688px;
    height: 387px;
  }

  .declarations {
    width: 65vw !important;
  }
}

@media only screen and (max-height: 800px) {
  .image img {
    width: 450px;
    height: 253px;
  }

  .sneaker_detail {
    height: calc(100vh - 140px);
  }
}

@media only screen and (min-height: 1200px) {
  .declarations {
    width: 40vw !important;
  }
}

.sneaker_detail {
  overflow-y: scroll;

  .sneaker-detail-content {
    display: flex;
    margin-bottom: 40px;

    .image {
      margin-right: 60px;
    }

    .detail-content {
      .attributes {
        margin-left: 50px;
        display: flex;

        .row {
          min-width: 200px;
          margin-right: 130px;
          display: flex;
          flex-direction: column;
        }
      }

      .date {
        color: #aeb3bb;
        margin: -30px 0px 20px 50px;
      }

      .attribute {
        margin-bottom: 20px;
      }

      .attribute-title {
        margin-bottom: 5px;
      }

      .buttons {
        display: flex;
        margin-top: 15px;
        margin-left: 50px;
      }

      button {
        margin-right: 30px;
      }
    }
  }

  .declarations {
    width: calc(100vw - 120px);

    h1 {
      margin-bottom: 15px;
    }

    .declarations_container {
      overflow-y: scroll;
      height: 210px;

      p {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}
</style>
