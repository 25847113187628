<template>
  <div class="declaration-form-content">
    <v-header
      :to="{ name: 'declarations' }"
      :text="$t('_declarations.submit')"
    />
    <form class="form" @submit.prevent="createDeclaration" novalidate>
      <v-select
        :options="declaration_types"
        :values="declaration_types"
        :label="$t('_declarations_create.declaration_type')"
        :placeholder="$t('_declarations_create.declaration_type_placeholder')"
        v-on:setValue="setDeclarationType"
        :error="$v.declaration.type"
      />
      <h3 v-show="declaration.type == 'Reiskosten'">
        {{ $t("_declarations_create.amount_of_km") }}
      </h3>
      <v-input
        ref="declaration_distance"
        v-show="declaration.type == 'Reiskosten'"
        type="number"
        v-model="declaration.distance"
        :error="$v.declaration.distance"
        min="0"
        step="0.1"
        v-on:changeValue="changeDistance"
      />
      <v-select
        ref="declaration_car"
        v-show="declaration.type == 'Reiskosten' && declaration.distance"
        :options="car_types"
        :values="car_types"
        :label="$t('_declarations_create.select_car')"
        :placeholder="$t('_declarations_create.select_car_placeholder')"
        v-on:setValue="setCar"
        :error="$v.declaration.car_brand"
      />
      <v-select
        ref="declaration_is_sneaker"
        v-show="
          (declaration.type && declaration.type != 'Reiskosten') ||
            (declaration.type &&
              declaration.type == 'Reiskosten' &&
              declaration.car_brand)
        "
        :options="declaration_for_options"
        :values="declaration_for_options"
        :label="$t('_declarations_create.is_sneaker')"
        :placeholder="$t('_declarations_create.is_sneaker_placeholder')"
        :error="$v.declarationFor"
        v-on:setValue="setDeclarationFor"
      />
      <v-select
        ref="declaration_sneaker_type"
        v-show="declarationFor == 'Ja'"
        :options="sneakertypes"
        :values="sneakertypes"
        :label="$t('_declarations_create.sneaker_type')"
        :placeholder="$t('_declarations_create.sneaker_type_placeholder')"
        v-on:setValue="setSneakerType"
        :error="$v.declaration.sneaker_type"
      />
      <v-select
        ref="sneaker"
        v-show="declarationFor == 'Ja' && declaration.sneaker_type"
        :options="sneaker_names"
        :values="sneaker_ids"
        :label="$t('_declarations_create.sneaker')"
        :placeholder="$t('_declarations_create.sneaker_placeholder')"
        v-on:setValue="setSneakerId"
        :error="$v.declaration.sneakerId"
      />
      <div class="general" v-show="showGeneral">
        <h3>
          {{ $t("_declarations_create.amount") }}
        </h3>
        <v-input
          ref="amount"
          :disabled="declaration.type == 'Reiskosten'"
          type="number"
          step="0.01"
          min="0"
          v-model="declaration.amount"
          v-on:changeValue="changeAmount"
          :error="$v.declaration.amount"
        />
        <h3>
          {{ $t("_declarations_create.date") }}
        </h3>
        <v-input
          type="date"
          :error="$v.declaration.date_string"
          v-model="declaration.date_string"
        />
        <v-select
          :options="status_options"
          :values="status_options"
          :label="$t('_declarations_create.status')"
          :placeholder="$t('_declarations_create.status_placeholder')"
          v-on:setValue="setStatus"
          :error="$v.declaration.status"
        />
      </div>
      <v-button
        v-show="showGeneral"
        :label="$t('_buttons.submit')"
        position="small"
      />
    </form>
  </div>
</template>

<script>
import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "DeclarationsCreate",
  props: {},
  data() {
    return {
      declaration_for_options: [
        this.$t("_declarations_create._answer.yes"),
        this.$t("_declarations_create._answer.no"),
      ],
      status_options: [
        this.$t("_declarations_create._status.submitted"),
        this.$t("_declarations_create._status.payed_out"),
      ],
      car_types: [
        this.$t("_declarations_create._car.mercedes"),
        this.$t("_declarations_create._car.up"),
      ],
      declaration_types: [
        this.$t("_declarations_create._type.purchase"),
        this.$t("_declarations_create._type.food"),
        this.$t("_declarations_create._type.meal_allowance"),
        this.$t("_declarations_create._type.driving_costs"),
        this.$t("_declarations_create._type.other"),
      ],
      declaration: {
        type: "",
        status: "",
        date_string: "",
        amount: undefined,
        sneakerId: undefined,
        distance: undefined,
        car_brand: undefined,
        sneaker_type: undefined,
      },
      declarationFor: {},
      kilometer_allowance: {},
      sneakertypes: [],
      sneaker_names: [],
      sneaker_ids: [],
      showGeneral: false,
      balance: {},
    };
  },
  validations: {
    declaration: {
      type: {
        required,
      },
      status: {
        required,
      },
      date_string: {
        required,
        checkYear: function(value) {
          if (value) {
            return value.split("-")[0] > 2019 && value.split("-")[0] < 2100;
          }
          return true;
        },
      },
      amount: {
        required,
        minValue: minValue(0.01),
      },
      sneakerId: {
        required: requiredIf(function() {
          return this.declarationFor == "Ja";
        }),
      },
      distance: {
        minValue: minValue(0.01),
        required: requiredIf(function() {
          return this.declaration.type == "Reiskosten";
        }),
      },
      car_brand: {
        required: requiredIf(function() {
          return this.declaration.type == "Reiskosten";
        }),
      },
      sneaker_type: {
        required: requiredIf(function() {
          return this.declarationFor == "Ja";
        }),
      },
    },
    declarationFor: {
      required,
    },
  },
  methods: {
    setDeclarationFor(declarationFor) {
      this.declarationFor = declarationFor;
      if (this.declarationFor == "Ja") {
        this.$store
          .dispatch("declarations/fetchSneakerTypes")
          .then((res) => {
            this.sneakertypes = res.data;
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
      if (this.declarationFor == "Nee") {
        this.$refs["declaration_sneaker_type"].reset();
        this.$refs["sneaker"].reset();
        this.showGeneral = true;
      }
    },
    setSneakerType(sneakerType) {
      this.sneaker_ids = [];
      this.sneaker_names = [];

      this.declaration.sneaker_type = sneakerType;
      if (this.declaration.sneaker_type) {
        this.$store
          .dispatch(
            "declarations/fetchSneakersByType",
            this.declaration.sneaker_type
          )
          .then((res) => {
            res.data.forEach((sneaker) => {
              this.sneaker_names.push(sneaker.name);
              this.sneaker_ids.push(sneaker.id);
            });
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    setSneakerId(sneakerId) {
      this.declaration.sneakerId = sneakerId;
      if (sneakerId) {
        this.showGeneral = true;
      }
    },
    setDeclarationType(type) {
      this.declaration.type = type;
      this.showGeneral = false;

      this.$refs["amount"].reset();
      this.$refs["declaration_is_sneaker"].reset();
      this.$refs["declaration_sneaker_type"].reset();
      this.$refs["sneaker"].reset();

      if (this.declaration.type != "Reiskosten") {
        this.$refs["declaration_distance"].reset();
        this.$refs["declaration_car"].reset();
      }
    },
    changeDistance(distance) {
      this.declaration.distance = distance;
      if (this.declaration.car_brand) {
        this.$refs["amount"].reset();
        this.$store
          .dispatch("declarations/fetchAllowance", this.declaration.car_brand)
          .then((res) => {
            this.kilometer_allowance = res.data;
            this.declaration.amount = (
              this.kilometer_allowance * this.declaration.distance
            ).toFixed(2);
            this.$refs["amount"].setValue(this.declaration.amount);
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    changeAmount(amount) {
      this.declaration.amount = amount;
    },
    setStatus(status) {
      this.declaration.status = status;
    },
    setCar(car) {
      this.declaration.car_brand = car;
      if (this.declaration.car_brand) {
        this.$refs["amount"].reset();
        this.$store
          .dispatch("declarations/fetchAllowance", this.declaration.car_brand)
          .then((res) => {
            this.kilometer_allowance = res.data;
            this.declaration.amount = (
              this.kilometer_allowance * this.declaration.distance
            ).toFixed(2);
            this.$refs["amount"].setValue(this.declaration.amount);
          })
          .catch(() => {
            this.$parent.showErrorMessage(
              this.$t("_errors.something_went_wrong_while_fetching_data")
            );
          });
      }
    },
    createDeclaration() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store
        .dispatch("declarations/create", this.declaration)
        .then(() => {
          this.balance = {
            new_expense: parseFloat(this.declaration.amount),
            new_revenue: 0,
            date_bought: this.declaration.date_string,
          };
          this.$store
            .dispatch("balance/postBalance", this.balance)
            .then(() => {
              this.$store
                .dispatch("payouts/updatePayout", {
                  payout_date: this.declaration.date_string,
                })
                .catch(() => {
                  this.$parent.showErrorMessage(
                    this.$t("_errors.something_went_wrong_while_fetching_data")
                  );
                });
            })
            .catch(() => {
              this.$parent.showErrorMessage(
                this.$t("_errors.something_went_wrong_while_fetching_data")
              );
            });
        })
        .catch(() => {
          this.$parent.showErrorMessage(
            this.$t("_errors.something_went_wrong_while_fetching_data")
          );
        })
        .finally(() => {
          this.$router.push({ name: "declarations" });
          this.$parent.showCheckMessage(
            this.$t("_declarations.created_success")
          );
          this.$v.$reset();
        });
    },
  },
  metaInfo() {
    return { title: this.$t("_declarations_create.page_title") };
  },
};
</script>

<style lang="scss" scoped>
.declaration-form-content {
  height: calc(100vh - 90px);
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-height: 800px) {
  .form {
    height: calc(100vh - 90px - 157px);
  }
}

@media only screen and (max-height: 800px) {
  .form {
    height: calc(100vh - 90px - 142px);
  }
}

.form {
  overflow-y: scroll;

  h3 {
    margin-left: 21px;
  }

  v-input {
    max-height: 12px;
  }

  .input-holder {
    margin-bottom: 30px;
  }

  .select-content,
  .input-holder {
    max-width: 256px;
  }
}
</style>
